/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Fragment, useState, useEffect } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Box, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { TextField } from '../../style-guide';
import './Registration.scss';
import { setCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession } from '../../reducers/user-reducers/UserSlicer';
import { snackbarToggle, snackbarAutoClose } from '../../reducers/snackbarSlicer';
import { registerUser, ping, validateCompanyName, validateMyId, validateEngText, validatePhone, validateEmail, copyToClipboard1, activateRegister, listWarehouse } from '../../reducers/requestHandler';
import DialogWithSideBar from '../../style-guide/Dialog/DialogWithSideBar';
import { autoCloseSnackbar } from '../../constants/global-constants';
import ActionButton from '../Common/ActionButton';
import OTPInput from 'otp-input-react';
import CloseIcon from '@mui/icons-material/Close';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import copy from '../../images/copy.png'
import { FaInfoCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import Loader from '../../style-guide/Loader';


const screens = ['Registeration', 'Password', 'OTPVerify', 'PersonalData'];
const countries = [
    { id: 1, val: "ארה”ב", name: "Ofer Milkin", addLine1: "Nashua, NH, 03063", addLine2: ".472 Amherst St", city: "Nashua", state: "NH", zipCode: "03063", phone: "781-491-0874" },
    { id: 2, val: "מערב אירופה", name: "Ofer Milkin", addLine1: "Nashua, NH, 03063", addLine2: ".472 Amherst St", city: "Nashua", state: "NH", zipCode: "03063", phone: "781-491-0874" },
    { id: 3, val: "פולין", name: "Ofer Milkin", addLine1: "Nashua, NH, 03063", addLine2: ".472 Amherst St", city: "Nashua", state: "NH", zipCode: "03063", phone: "781-491-0874" },
    { id: 4, val: "טורקיה", name: "Ofer Milkin", addLine1: "Nashua, NH, 03063", addLine2: ".472 Amherst St", city: "Nashua", state: "NH", zipCode: "03063", phone: "781-491-0874" },
];

const data = {
    otp: '',
    nickName: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    hpAuth: '',
    isCoupon: true,
    isTnc: true,
    countryCode: '',
    addressLine_1: '',
    addressLine_2: '',
    state: '',
    city: '',
    postal: '',
}

export const Registration = ({ tokenForCh, setOpenOrderModalRegistration, setIsAddAddress, setIsChangePass, isRegister, setIsRegister, setIsLogin, activeScreenInreg, setActiveScreenInreg }) => {
    const [value, setValue] = useState(data);
    const [activeScreen, setActiveScreen] = useState(activeScreenInreg);
    const [countriesList, setCountriesList] = useState(countries);
    const [warehouseList, setWarehouseList] = useState([]);
    const [isBusiness, setIsBusiness] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(1)
    const { userInfo, innerContent } = useSelector(state => state.user);
    
    const usTooltipAddress = `המרכז הלוגיסטי בארצות הברית ממוקם בניו ג'רסי ומטפל במשלוחים המגיעים מכלל המדינות בארה"ב`;
    const euTooltipAddressNoraml1 = 'המרכז הלוגיסטי באירופה ממוקם בהולנד ומטפל במשלוחים המגיעים';
    const euTooltipAddressNoraml2 = 'ממדינות האיחוד האירופי';
    const euTooltipAddressNoraml3 = 'הולנד, גרמניה, צרפת, בלגיה, דנמרק, פולין, איטליה, ספרד, פרטוגל';
    const euTooltipAddressBold1 = 'הערה: אנגליה ושוויץ, אינן חלק מהאיחוד האירופי ולכן עוברים תהליך מכס';
    const euTooltipAddressBold2 = 'בהולנד הכרוך בעלויות';
    const euTooltipAddress = 'המרכז הלוגיסטי באירופה ממוקם בהולנד ומטפל במשלוחים המגיעים  \n  ממדינות האיחוד האירופי  \n  הולנד, גרמניה, צרפת, בלגיה, דנמרק, פולין, איטליה, ספרד, פרטוגל  \n  הערה: אנגליה ושוויץ, אינן חלק מהאיחוד האירופי ולכן עוברים תהליך מכס  \n  בהולנד הכרוך בעלויות';
    const handleScreenData = (screen = '') => {
        if (!validateRegData()) {
            return;
        }
        if (screen === screens[0]) {
            // data handle for Registeration
        } else if (screen === screens[1]) {
            // data handle for Password            
        } else if (screen === screens[2]) {
            //register_user();
            // data handle for User activation 
        } else if (screen === screens[3]) {
            // data handle for OTPVerify
        }
        screen && setActiveScreen(screen)
    }
    const copyToClipboard = (text, id) => {
        copyToClipboard1(text, function (data) {
            var oldText = document.getElementById(id).innerText;
            document.getElementById(id).innerText = data;
            document.getElementById(id).setAttribute("style", "background:#baffba;");
            // dispatch(snackbarToggle({ type: 'success', message: data }));
            window.setTimeout(function () {
                //dispatch(snackbarToggle(false));
                document.getElementById(id).setAttribute("style", "background:none;");
                document.getElementById(id).innerText = oldText;
            }, 1500);
        })
    }
    const validateRegData = () => {
        if (activeScreen === screens[0] && !validatePhone(value.phone)) {
            dispatch(snackbarToggle({ type: 'error', message: "מספר טלפון לא תקין" }));
            return false;
        }
        if (activeScreen === screens[0] && !validateEmail(value.email)) {
            dispatch(snackbarToggle({ type: 'error', message: 'כתובת דואר לא תקינה' }));
            return false;
        }
        if (activeScreen === screens[0] && !value.isTnc) {
            dispatch(snackbarToggle({ type: 'error', message: 'אנא קבל את התנאים וההגבלות.' }));
            return false;
        }
        if (activeScreen === screens[1] && value?.password?.length < 8) {
            dispatch(snackbarToggle({ type: 'error', message: 'נא להזין סיסמה עם לפחות 8 אותיות.' }));
            return false;
        }
        if (activeScreen === screens[1] && value?.password !== value?.confirmPassword) {
            dispatch(snackbarToggle({ type: 'error', message: 'הסיסמה והסיסמאות שהוזנו מחדש אינן זהות.' }));
            return false;
        }
        if (activeScreen === screens[3] && value.countryCode === '') {
            dispatch(snackbarToggle({ type: 'error', message: 'בבקשה תבחר מדינה.' }));
            return false;
        }
        return true;
    }

    const getWarehouses = () => {
        listWarehouse(function (response) {
            if (response.misc.CODE === 1) {
                //  setWarehouseList(response.data);

                var data = [];
                for (var i = 0; i < response.data.length; i++) {
                    //if (response.data[i].name === "טורקיה") {
                    if (response.data[i].name === "ארה”ב") {
                        //data.push(response.data[i]);
                        setTabValue(response.data[i].id)
                    }
                }
                for (var i = 0; i < response.data.length; i++) {
                    //if (response.data[i].name !== "טורקיה" && response.data[i].name !== "'מערב אירופה'") {
                    if ( response.data[i].name !== "'מערב אירופה'") {
                        data.push(response.data[i]);
                    }
                }/**/
                var d = response.data.find(u => u.name === 'מערב אירופה');
                if (d) {
                    var l = response.data.length;
                    var t = { ...d }
                    t.name = "אירופה";
                    t.id = d.id + 1 + l;
                    data.push(t);
                }
                console.log("*************")
                console.log(data)
                setWarehouseList(data);
            } else {
                dispatch(snackbarToggle({ type: 'error', message: "הפעלה נכשלה." }));
            }
        });
    }
    useEffect(() => {
        getWarehouses();
        if (activeScreenInreg == 'OTPVerify') {
            setValue(prev => ({ ...prev, otp: tokenForCh }))
        }
    }, [dispatch]);
    const activateRegisterByOtp = () => {
        var d = {
            token: value.otp
        }
        setIsLoading(true);
        activateRegister(d, function (response) {
            setIsLoading(false);
            if (response.misc.CODE === 1) {
                dispatch(snackbarToggle({ type: 'success', message: "ההרשמה הופעלה." }));
                handleScreenData(screens[3]);
                setOpenOrderModalRegistration(true)
                setCookie("SESSION_ID", response.session, 30);
                dispatch(getSession(response))
            } else {
                dispatch(snackbarToggle({ type: 'error', message: "הפעלה נכשלה." }));
            }
        });
    }
    const [isLoading, setIsLoading] = new useState(false);
    const register_user = () => {
        if (!validateRegData()) {
            return;
        }
        setIsLoading(true);
        setActiveScreen(screens[0])
        var data = {
            userName: value?.email,
            firstName: value?.firstName,
            lastName: value?.lastName,
            phone: value?.phone,
            nickName: '',
            password: value?.password,
            email: value?.email,
            isComm: value?.isComm,
            setting: {
                "companyId": value.companyId,
                "register_company": value.companyName,
                "register_countryCode": value.countryCode,
                "register_addressLine_1": value.addressLine_1,
                "register_addressLine_2": value.addressLine_2,
                "register_state": value.state,
                "register_city": value.city,
                "isCouponAndMail": value.isCoupon,
                "register_postal": value.postal
            },
        }
        registerUser(data, function (response) {
            setIsLoading(false);
            if (response.misc.CODE === 1) {
                dispatch(snackbarToggle({ type: 'success', message: `${response.message}` }));
                ping(response.session, function (response1) {
                    if (response1.misc.CODE === 1) {
                        setCookie("SESSION_ID", response1.session, 30);
                        dispatch(getSession(response1))
                        navigate("/home")
                    } else {
                        navigate("/login")
                    }
                })
                setIsRegister(false);
            }
            else if (response.misc.CODE === 2) {
                setActiveScreen(screens[2])
                // handleScreenData(screens[1])
                dispatch(snackbarToggle({
                    type: 'success', message: "תודה על הרישום לאתר. מייל ישלח לתיבת הדואר לאישור. במידה ולא התקבל, יש לבדוק בSPAM"
                }));
            } else if (response.misc.CODE === 3) {
                setActiveScreen(screens[0])

                if (response.misc.MESSAGE == "DUPLICATE_EMAIL") {
                    dispatch(snackbarToggle({
                        type: 'error', message: "משתמש כבר קיים במערכת"
                    }));
                } else if (response.misc.MESSAGE == "DUPLICATE_USERNAME") {
                    dispatch(snackbarToggle({
                        type: 'error', message: "משתמש כבר קיים במערכת"
                    }));
                } else if (response.misc.MESSAGE == "DUPLICATE_PHONE") {
                    dispatch(snackbarToggle({ type: 'error', message: "מספר הטלפון כבר קיים במערכת" }));
                } else {
                    dispatch(snackbarToggle({
                        type: 'error', message: response.message
                    }));
                }
            }
        });
    }

    console.log('data', data);

    const onClickAlreadyRegister = () => {
        setIsLogin(true);
        setIsChangePass(false);
        setIsRegister(false);
        setActiveScreenInreg(screens[0]);
    }

    const handleOtp = (val) => {
        setValue(prev => ({ ...prev, otp: val }))
    }
    const validatePassword = (val) => {
        return /^[A-Za-z0-9]*$/.test(val);
    }
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const [isEye, setIsEye] = new useState(true);
    const [isConfirmEye, setIsConfirmEye] = new useState(true);
    return (
        <DialogWithSideBar
            open={isRegister}
            isOutClose={true}
            onClose={() => { setIsRegister(false); setActiveScreenInreg(screens[0]) }}
        >
            <Fragment>
                {/*{activationSuccess && <noscript>
                        <iframe src="https://hooks.zapier.com/hooks/catch/2031548/23set3g/"
                            height="0" width="0" style="display:none;visibility:hidden"></iframe>
                    </noscript>}*/}
                {isLoading && <Loader isFullDisplay />}
                {/* Registration Screen */}
                {activeScreen === screens[0] &&
                    <Box className="reg_popup_data">
                        <Box className="popup_tittle">
                            מלאו את הפרטים לקבלת כתובת בחו״ל
                        </Box>
                        <Box className='row' >
                            {!isMobile() && <Box className='col-md-12' style={{ display: "flex", flexDirection: "row-reverse" }}>
                                <Box className='col-md-6' >
                                    <TextField
                                        className='text-field'
                                        required
                                        dir={'rtl'}
                                        tabIndex={"1"}
                                        value={value.firstName}
                                        onChange={event => {
                                            if (!validateEngText(event.target.value)) {
                                                event.preventDefault();
                                                return;
                                            }
                                            setValue(prev => ({ ...prev, firstName: event.target.value }))
                                        }}
                                        label='שם פרטי באנגלית'
                                        onBlur={validateEngText(value.firstName)}
                                    />
                                    {!validateEngText(value.firstName) && value.firstName && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                        Enter English text only
                                    </Box>
                                    }
                                </Box>
                                <Box className='col-md-6' >
                                    <TextField
                                        className='text-field'
                                        required
                                        dir={'rtl'}
                                        tabIndex={"2"}
                                        value={value.lastName}
                                        onChange={event => {
                                            if (!validateEngText(event.target.value)) {
                                                event.preventDefault();
                                                return;
                                            }
                                            setValue(prev => ({ ...prev, lastName: event.target.value }))
                                        }}
                                        label='שם משפחה באנגלית'
                                        onBlur={validateEngText(value.lastName)}
                                    />
                                    {!validateEngText(value.lastName) && value.lastName && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                        Enter English text only
                                    </Box>
                                    }
                                </Box>

                            </Box>
                            }
                            {isMobile() && <Box className='col-md-6' >
                                <TextField
                                    className='text-field'
                                    required
                                    dir={'rtl'}
                                    tabIndex={"1"}
                                    value={value.firstName}
                                    onChange={event => {
                                        if (!validateEngText(event.target.value)) {
                                            event.preventDefault();
                                            return;
                                        }
                                        setValue(prev => ({ ...prev, firstName: event.target.value }))
                                    }}
                                    label='שם פרטי באנגלית'
                                    onBlur={validateEngText(value.firstName)}
                                />
                                {!validateEngText(value.firstName) && value.firstName && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                    Enter English text only
                                </Box>
                                }
                            </Box>}
                            {isMobile() && <Box className='col-md-6' tabIndex={2}>
                                <TextField
                                    className='text-field'
                                    required
                                    dir={'rtl'}
                                    value={value.lastName}
                                    onChange={event => {
                                        if (!validateEngText(event.target.value)) {
                                            event.preventDefault();
                                            return;
                                        }
                                        setValue(prev => ({ ...prev, lastName: event.target.value }))
                                    }}
                                    label='שם משפחה באנגלית'
                                    onBlur={validateEngText(value.lastName)}
                                />
                                {!validateEngText(value.lastName) && value.lastName && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-20px" }}>
                                    Enter English text only
                                </Box>
                                }
                            </Box>}
                            <Box className='col-md-12'>
                                <TextField
                                    className='text-field'
                                    required
                                    tabIndex={"3"}
                                    dir={'rtl'}
                                    value={value.email}
                                    onChange={event => setValue(prev => ({ ...prev, email: event.target.value }))}
                                    label='כתובת אימייל'
                                    onBlur={event => {
                                        if (!validateEmail(value.email)) {
                                            dispatch(snackbarToggle({ type: 'error', message: `כתובת דואר לא תקינה` }));
                                        }
                                    }}
                                />
                            </Box>
                            <Box className='col-md-12'>
                                <TextField
                                    className='text-field'
                                    required
                                    tabIndex={"3"}
                                    dir={'rtl'}
                                    onPaste={event => {
                                        event.preventDefault();
                                    }}
                                    value={value.confirmEmail}
                                    onChange={event => setValue(prev => ({ ...prev, confirmEmail: event.target.value }))}
                                    label='אישור כתובת אימייל'
                                    onBlur={event => {
                                        if (!validateEmail(value.confirmEmail)) {
                                            dispatch(snackbarToggle({ type: 'error', message: `כתובת דואר לא תקינה` }));
                                        }
                                    }}

                                />
                                {value.confirmEmail != value.email && value.email && value.confirmEmail && <Box className='col-md-12' style={{ direction: "rtl", color: "red" }}>
                                    אימייל לא תואם
                                </Box>
                                }
                            </Box>
                            <Box className='col-md-12'>
                                <TextField
                                    className='text-field'
                                    required
                                    dir={'rtl'}
                                    tabIndex={"4"}
                                    value={value.phone}
                                    onChange={event => setValue(prev => ({ ...prev, phone: event.target.value }))}
                                    label='מספר טלפון נייד'
                                    onBlur={event => {
                                        if (!validatePhone(value.phone)) {
                                            // dispatch(snackbarToggle({ type: 'error', message: `מספר טלפון לא תקין` }));
                                        }
                                    }}
                                />
                                {!validatePhone(value.phone) && value.phone && <Box className='col-md-12' style={{ direction: "rtl", color: "red" }}>
                                    מספר טלפון לא תקין
                                </Box>
                                }
                            </Box>
                        </Box>
                        {isBusiness &&
                            <Box className="row">
                                <Box className="col-lg-12">
                                    <TextField
                                        className='text-field'
                                        required
                                        dir={'rtl'}
                                        tabIndex={"5"}
                                        value={value.companyName}
                                        onChange={event => setValue(prev => ({ ...prev, companyName: event.target.value }))}
                                        label='שם החברה באנגלית'
                                    />
                                </Box>
                                <Box className="col-lg-12">
                                    <TextField
                                        className='text-field'
                                        required
                                        tabIndex={"6"}
                                        dir={'rtl'}
                                        value={value.hpAuth}
                                        onChange={event => setValue(prev => ({ ...prev, hpAuth: event.target.value }))}
                                        label='ח.פ / עוסק מורשה'
                                    />
                                </Box>
                            </Box>
                        }
                        {value.isComm &&
                            <Box className="row">
                                <Box className="col-lg-12">
                                    <TextField
                                        className='text-field'
                                        required
                                        dir={'rtl'}
                                        tabIndex={"5"}
                                        value={value.companyName}
                                        onChange={event => setValue(prev => ({ ...prev, companyName: event.target.value }))}
                                        label='שם החברה'
                                    />
                                </Box>
                                {!validateCompanyName(value.companyName) && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-10px" }}>
                                    {`שם החברה אינו תקין`}<br />{`השם צריך להכיל תווים באנגלית/עברית ללא תווים מיוחדים`}
                                </Box>}
                                <Box className="col-lg-12">
                                    <TextField
                                        className='text-field'
                                        required
                                        tabIndex={"6"}
                                        dir={'rtl'}
                                        value={value.companyId}
                                        onChange={event => setValue(prev => ({ ...prev, companyId: event.target.value }))}
                                        label='ח״פ/ע״מ'
                                    />
                                </Box>
                                {!validateMyId(value.companyId) && <Box className='col-md-12' style={{ direction: "rtl", color: "red", top: "-10px" }}>
                                    {`מספר מזהה אינו תקין`}
                                </Box>}
                            </Box>
                        }

                    </Box>
                }
                {/* Set Password Screen */}
                {activeScreen === screens[0] &&
                    <Box className="reg_popup_data">
                        {/* <Box className="popup_tittle">
                            {"מלאו את הפרטים"}
                        </Box>*/}

                        <Box>
                            {/*<TextField
                                className='text-field'
                                required
                                dir={'rtl'}
                                value={value.password}
                                onChange={event => setValue(prev => ({ ...prev, password: event.target.value }))}
                                label='סיסמה'
                                type={'password'}
                            />*/}
                            <Grid container className='position-reletive'>
                                <Grid onClick={() => { setIsEye(!isEye) }} className="prefix-field">
                                    {isEye
                                        ? <FaEye style={{ fill: "#625252", width: "100%", height: "30%" }} />
                                        : <FaEyeSlash style={{ fill: "#625252", width: "100%", height: "30%" }} />}
                                </Grid>
                                <TextField
                                    className='text-field'
                                    required
                                    dir="rtl"
                                    value={value.password}
                                    onChange={event => setValue(prev => ({ ...prev, password: event.target.value }))}
                                    label='סיסמה'
                                    tabIndex={"5"}
                                    type={`${isEye ? 'password' : 'text'}`}
                                    onKeyDown={e => keyPressed(e)}
                                    parentClassName={'prefix-password'}
                                />
                            </Grid>
                            <Grid container className='position-reletive'>
                                <Grid onClick={() => { setIsConfirmEye(!isConfirmEye) }} className="prefix-field">
                                    {isConfirmEye
                                        ? <FaEye style={{ fill: "#625252", width: "100%", height: "30%" }} />
                                        : <FaEyeSlash style={{ fill: "#625252", width: "100%", height: "30%" }} />}
                                </Grid>
                                <TextField
                                    className='text-field'
                                    required
                                    dir="rtl"
                                    tabIndex={"6"}
                                    value={value.confirmPassword}
                                    onChange={event => setValue(prev => ({ ...prev, confirmPassword: event.target.value }))}
                                    label='אימות'
                                    type={`${isConfirmEye ? 'password' : 'text'}`}
                                    onKeyDown={e => keyPressed(e)}
                                    parentClassName={'prefix-password'}
                                />
                            </Grid>
                            {(value.confirmPassword != value.password) && value.password && value.confirmPassword && <Box className='col-md-12' style={{ direction: "rtl", color: "red" }}>
                                הסיסמה לא תואמת
                            </Box>
                            }
                            {!validatePassword(value.password) && <Box className='col-md-12' style={{ direction: "rtl", color: "red" }}>
                                { `סיסמא לא חוקית , הסיסמא צריכה לכלול מספרים ואותיות בלבד`}
                            </Box>
                            }
                            {/*<TextField
                                className='text-field'
                                required
                                dir={'rtl'}
                                value={value.confirmPassword}
                                onChange={event => setValue(prev => ({ ...prev, confirmPassword: event.target.value }))}
                                label='אשר סיסמה'
                                type={'password'}
                            />*/}
                        </Box>
                        <Box container className='checkboxs'>
                            <FormControlLabel dir='rtl' tabIndex={"7"} key={uuidv4()} control={< Checkbox checked={value?.isCoupon} onChange={event => setValue(prev => ({ ...prev, isCoupon: event.target.checked }))} />} label={"אני רוצה לקבל קופונים והטבות במייל"} />
                            {/* <FormControlLabel dir='rtl' key={uuidv4()} control={< Checkbox checked={value?.isTnc} onChange={event => setValue(prev => ({ ...prev, isTnc: event.target.checked }))} />} label={} />*/}
                            {/* <FormControlLabel dir='rtl' tabIndex={"8"} key={uuidv4()} control={< Checkbox checked={value?.isTnc} onChange={event => setValue(prev => ({ ...prev, isTnc: event.target.checked }))} />} label={<label>אני מאשר/ת  שקראתי את <a onClick={() => { window.setTimeout(function () { window.open("/FAQS?action=from_registration", "_blank"); }, 500) }}>תנאי השימוש</a> באתר ואני מסכים/ה להם</label>} />*/}
                            <FormControlLabel dir='rtl' tabIndex={"8"} key={uuidv4()} control={< Checkbox checked={value?.isTnc} onChange={event => setValue(prev => ({ ...prev, isTnc: event.target.checked }))} />} label={<label>אני מאשר/ת  שקראתי את <a href="/TnC.pdf" target="_blank">תנאי השימוש</a> באתר ואני מסכים/ה להם</label>} />
                            <FormControlLabel dir='rtl' tabIndex={"7"} key={uuidv4()} control={< Checkbox checked={value?.isComm} onChange={event => setValue(prev => ({ ...prev, isComm: event.target.checked }))} />} label={`לקוח מסחרי`} />
                        </Box>
                        <Box className='form-action' tabIndex={"9"}>
                            <ActionButton disabled={!value?.isTnc || !validatePassword(value.password) || !value.email || (value.email != value.confirmEmail) || (value.confirmPassword != value.password) || value.lastName?.trim().length < 2 || value.firstName?.trim().length < 2} className={'submit-action'} textLabel={"רישום"} onClick={() => { handleScreenData(screens[0]); register_user(); }} />
                        </Box>

                        <Box className="screen-footer">
                            כבר רשומים ? <span onClick={() => { onClickAlreadyRegister(); }} className='link'> לחצו כאן כדי להתחבר </span>
                        </Box>
                        {/*<Box className='form-action'>
                            <ActionButton className={'submit-action'} textLabel={'המשך'} onClick={() => register_user()} />
                        </Box>*/}
                    </Box>
                }
                {/* OTP Verification Screen */}
                {activeScreen === screens[2] &&
                    <Box className="reg_popup_data">
                        <Box className="popup_tittle" >
                            שלחנו קוד אימות
                            למספר {value.email}
                        </Box>
                        <Box item lg={12} className="popup_tittle screen_otp_title_2">
                            הזינו את הקוד שקיבלתם
                        </Box>
                        <Box container justifyContent="space-between" className="dialog_grid" sx={{ mb: '8.62vh !important' }}>
                            <OTPInput className='opt-input-fileds' value={value.otp} onChange={handleOtp} autoFocus OTPLength={6} otpType="number" disabled={false} />
                        </Box>

                        <Box container item lg={12} justifyContent="center" alignContent="center" className="popup_tittle screen_otp_footer_2" sx={{ mb: '7.8vh  !important' }}>

                            <Box component={'span'} style={{ fontSize: "15px" }} onClick={() => register_user()} > שלחו לי שוב את הקוד </Box>
                        </Box>
                        <Box className='form-action'>
                            <ActionButton id="RBPREGISTRATION" className={'submit-action'} textLabel={'אישור'} onClick={activateRegisterByOtp} />
                        </Box>
                    </Box>
                }
                {/* Country Setting Screen */}
                {activeScreen === screens[3] &&
                    <Box className="reg_popup_data">
                        <Box className="popup_tittle">
                            ! איזה כיף
                        </Box>
                        <Box className="popup_tittle screen_otp_title_2" style={{ fontSize: "20px", margin: "0 !important", marginBottom: "10px !important" }}>
                            יצרנו בשבילך כתובות לכל היעדים שלנו  כל מה שנשאר זה לקנות
                        </Box>
                        <Box className="popup_tittle screen_otp_title_2" style={{ fontSize: "18px", margin: "0 !important", marginBottom: "10px !important" }}>
                            <span style={{ direction: "rtl" }}> {<span style={{ fontWeight: "bold" }}>{`חשוב! `}</span>}{`יש להוסיף בהזנת הכתובת את המזהה האישי המופיע  ב`}{<span style={{ fontWeight: "bold" }}> Address  2</span>} </span>
                        </Box>
                        <Box className="screen-data-title-bottom screen_otp_title_2" style={{ fontSize: "18px", marginTop: "10px", marginBottom: "10px" }}>
                            ניתן לעדכן <a style={{ textDecoration: "underline" }} onClick={() => { setIsRegister(false); setIsAddAddress(true); }}>כתובות למשלוח </a> בפרטים האישים לאחר הזמנת המוצר
                        </Box>
                        <TabContext value={tabValue}>
                            <TabList onChange={handleChange} aria-label="country-tabs" className='country-tabs' style={{ overflow: "auto" }}>
                                {/*iconPosition="end" icon={<FaInfoCircle style={{ margin: "10px" }} />}*/}
                                {warehouseList.map(country => (<> {country.name != "פולין" && country.name != "מערב אירופה"/*&& country.name != "ארה”ב"*/ && country.params?.active && <Tab  key={country.id} label={country.name} value={country.id} sx={{ direction:"rtl", fontWeight: "bold", height: "51px", padding: 0, backgroundColor: (tabValue == country.id) ? 'white !important' : "#f6f6f6", border: "solid 1px #f0f0f2", width: '25% !important', color: (tabValue == country.id) ? '#d70007 !important' : "black", fontSize: (tabValue == country.id) ? '2.4vh !important' : "2.4vh !important" }} onClick={(event) => { handleChange(event, country.id) }}>
                                </Tab>}</>))}
                            </TabList>

                            {warehouseList.map((country, index) => (
                                <>{country.name != "פולין" && country.name != "מערב אירופה" /*&& country.name != "ארה”ב"*/ && country.params?.active && <TabPanel className='country-tab-data' key={country.id + index} value={country.id} sx={{ padding: '0 !important' }}>
                                    <Grid container lg={12} className="data-row" style={{ margin: "10px" }} >
                                        <Box className="dataLabel data-col bold_text" style={{ opacity: 1, width:"100%" }}>
                                            <div lg={12} className="tooltip" style={{ opacity: 1 , width:"100%"}}> 
                                                <Grid container lg={12} >
                                                    <FaInfoCircle style={{ margin: "10px" }} /> <p style={{ margin: "7px" }} >{country.name}</p>
                                                </Grid>
                                                {
                                                    country.name == "אירופה" ?
                                                        <span className="tooltiptext" style={{ textAlign:"right" }}>
                                                            <p style={{ margin: "0", marginRight:"5px" }}>
                                                                { `המרכז הלוגיסטי ממוקם בהולנד ומטפל במשלוחים המגיעים ממדינות האיחוד האירופי`}
                                                            </p>
                                                            <p style={{ margin: "0", marginRight: "5px" }}>
                                                                { `הולנד, גרמניה, צרפת, בלגיה, דנמרק, פולין, איטליה, ספרד, פורטוגל , פינלנד ושבדיה`}
                                                            </p>
                                                            <b >
                                                                <p style={{ margin: "0", marginRight: "5px" }}>
                                                                    {`הערה: אנגליה ושוויץ, אינן חלק מהאיחוד האירופי ולכן, עוברים תהליך מכס בהולנד הכרוך בעלויות` }
                                                                </p>
                                                            </b>
                                                        </span> :
                                                        <span className="tooltiptext" style={{ textAlign: "right" }}>
                                                            <p style={{ margin: "0", marginRight: "5px" }}>
                                                                {`המרכז הלוגיסטי בארה"ב ממוקם בניו ג'רסי ומטפל במשלוחים המגיעים מכלל המדינות בארה"ב`}
                                                            </p>
                                                        </span>
                                                }
                                            </div>
                                        </Box>
                                        <Box className="dataLabel data-col bold_text" style={{ padding:0 }}></Box>
                                    </Grid>
                                    <Grid container className="data-row">
                                        <Box className="dataLabel data-col bold_text" > {'First Name'} </Box>
                                        <Box className="dataValue data-col" > {userInfo?.user?.firstName} </Box>
                                        <Box className="dataCopy data-col">
                                            <Button onClick={() => copyToClipboard(userInfo?.user?.firstName, 'copyButon1' + index)}>
                                                <img src={copy} alt="Copy" className="fivepx_margin" /><span id={'copyButon1' + index}> העתק </span>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid container className="data-row">
                                        <Box className="dataLabel data-col" > {'Last Name'} </Box>
                                        <Box className="dataValue data-col"> {userInfo?.user?.lastName} </Box>
                                        <Box className="dataCopy data-col">
                                            <Button onClick={() => copyToClipboard(userInfo?.user?.lastName, 'copyButon2' + index)}>
                                                <img src={copy} alt="Copy" className="fivepx_margin" /><span id={'copyButon2' + index}> העתק </span>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid container className="data-row">
                                        <Box className="dataLabel data-col"> {'Address 1'} </Box>
                                        <Box className="dataValue data-col"> {country.address} </Box>
                                        <Box className="dataCopy data-col">
                                            <Button onClick={() => copyToClipboard(country.address, 'copyButon3' + index)}>

                                                <img src={copy} alt="Copy" className="fivepx_margin" /><span id={'copyButon3' + index}> העתק </span>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid container className="data-row">
                                        <Box className="dataLabel data-col"> {'Address 2'} </Box>
                                        <Box className="dataValue data-col"> {userInfo?.user?.token} </Box>
                                        <Box className="dataCopy data-col">
                                            <Button onClick={() => copyToClipboard(userInfo?.user?.token, 'copyButon4' + index)}>
                                                <img src={copy} alt="Copy" className="fivepx_margin" /><span id={'copyButon4' + index}> העתק </span>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid container className="data-row">
                                        <Box className="dataLabel data-col"> {'City'} </Box>
                                        <Box className="dataValue data-col"> {country?.city || "-"} </Box>
                                        <Box className="dataCopy data-col">
                                            <Button onClick={() => copyToClipboard((country?.city || "-"), 'copyButon5' + index)}>
                                                <img src={copy} alt="Copy" className="fivepx_margin" /><span id={'copyButon5' + index}> העתק </span>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    {country?.country == 'Turkey' && <Grid container className="data-row">
                                        <Box className="dataLabel data-col"> {'District / Town'} </Box>
                                        <Box className="dataValue data-col"> {'Tayakadın Mah'} </Box>
                                        <Box className="dataCopy data-col">
                                            <Button onClick={() => copyToClipboard('Arnavutköy', 'copyButon6' + index)}>
                                                <img src={copy} alt="Copy" className="fivepx_margin" /><span id={'copyButon6' + index}> העתק </span>
                                            </Button>
                                        </Box>
                                    </Grid>}
                                    <Grid container className="data-row">
                                        <Box className="dataLabel data-col"> {'Region' + (country?.country == 'Turkey' ? ' / Neighbourhood' : '')} </Box>
                                        <Box className="dataValue data-col"> {country?.region || "-"} </Box>
                                        <Box className="dataCopy data-col">
                                            <Button onClick={() => copyToClipboard((country?.region || "-"), 'copyButon7' + index)}>
                                                <img src={copy} alt="Copy" className="fivepx_margin" /><span id={'copyButon7' + index}> העתק </span>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid container className="data-row">
                                        <Box className="dataLabel data-col"> {'Country'} </Box>
                                        <Box className="dataValue data-col"> {country?.country || "-"}</Box>
                                        <Box className="dataCopy data-col">
                                            <Button onClick={() => copyToClipboard((country?.country || "-"), 'copyButon8' + index)}>
                                                <img src={copy} alt="Copy" className="fivepx_margin" /><span id={'copyButon8' + index}> העתק </span>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid container className="data-row">
                                        <Box className="dataLabel data-col"> {'Zip/Post Code'} </Box>
                                        <Box className="dataValue data-col"> {country?.zip || "-"}</Box>
                                        <Box className="dataCopy data-col">
                                            <Button onClick={() => copyToClipboard((country?.zip || "-"), 'copyButon9' + index)}>
                                                <img src={copy} alt="Copy" className="fivepx_margin" /><span id={'copyButon9' + index}> העתק </span>
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid container className="data-row">
                                        <Box className="dataLabel data-col"> {'Phone'} </Box>
                                        <Box className="dataValue data-col"> {country.phone} </Box>
                                        <Box className="dataCopy data-col">
                                            <Button onClick={() => copyToClipboard(country.phone, 'copyButon10' + index)}>
                                                <img src={copy} alt="Copy" className="fivepx_margin" /><span id={'copyButon10' + index}> העתק </span>
                                            </Button>
                                        </Box>
                                    </Grid>

                                </TabPanel>}</>))}
                        </TabContext>

                        <Box className='form-action'>
                            <ActionButton className={'submit-action mt-24'} textLabel={'לאזור האישי'} onClick={() => { navigate("/accounts"); setIsRegister(false); }} />
                        </Box>
                    </Box>
                }
            </Fragment>
        </DialogWithSideBar>
    );
}

export default Registration;