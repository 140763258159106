import { memo } from 'react';

import { Grid, TextField } from '@mui/material';
//import { MobileDateTimePicker } from '@mui/lab';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
const DateTimePicker = ({ value, label, onChange, required, xs, sm, md, disabled, height }) => {
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const getTimezoneOffset = value => value.getTimezoneOffset() * 60000;
    const getUTC_DATE = function (date) {
        // var date = new Date();
        if (date) {
            var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
            return now_utc;
        }
        return new Date(0);
    }
    const makeLocalAppearUTC = value => {
        if (value) {
            const dateTime = new Date(value);
            const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
            return utcFromLocal;
        }
        return new Date(0);
    };

    const localToUTC = dateTime => {
        const utcFromLocal = new Date(dateTime.getTime() - getTimezoneOffset(dateTime));
        return utcFromLocal;
    };
    return (
        <Grid className={"datetimeComponent"} item xs={xs} sm={sm} md={md} style={{direction: "rtl", marginTop:`${!isMobile() ? "17px" : "11px"}`}}>
            <MobileDateTimePicker
                disabled={disabled}
                value={makeLocalAppearUTC(value)}
                inputFormat="dd/MM/yyyy"
                label="Select Date/Time (GMT)"
                onChange={(value) => {
                    onChange(localToUTC(value))
                }}
                renderInput={params => <TextField
                    InputProps={{
                        sx:{background:"white"}
                    }}
                    size='small'
                    fullWidth
                    {...params}
                    label={label}
                    required={required}
                />}
            />
        </Grid>
    );
};

DateTimePicker.defaultProps = {
    disabled: false,
    label: '',
    value: null,
    required: null,
    xs: 12,
    sm: 6,
    md: 4,
    height:"30px",
};

export default memo(DateTimePicker);
