import React, { useState, useEffect, Fragment } from 'react';
import { Box, Button, Grid, List, ListItem, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RedboxLogo from './../../images/newHomePage/redbox-logo.png';
import HomeBanner from './../../images/newHomePage/homeBanner.png';
import SignUp from './../../images/newHomePage/signup.png';
import AreBuying from './../../images/newHomePage/areBuying.png';
import RedboxService from './../../images/newHomePage/redbox-service.png';
import ShipmentOnWay from './../../images/newHomePage/shipmentOnWay.png';
import img1 from './1.png';
import img2 from './2.png';
import img3 from './3.png';
import img4 from './4.png';
import img5 from './5.png';
import img6 from './6.png';
import BillIcon from './../../images/newHomePage/bill.png';
import OnlineOrderIcon from './../../images/newHomePage/onlineOrder.png';
import ShoppingIcon from './../../images/newHomePage/shopping.png';
import DeliveryIcon from './../../images/newHomePage/delivery.png';
import OrderDoneIcon from './../../images/newHomePage/orderDone.png';
import CoinsIcon from './../../images/newHomePage/coins.png';
import ProdDropdown from './../../images/newHomePage/prodDropdown.png';
import ShoppingCouple from './../../images/newHomePage/shoppingCouple.png';
import HnMIcon from './../../images/newHomePage/hnmIcon.png';
import MACIcon from './../../images/newHomePage/macIcon.png';
import ZaraIcon from './../../images/newHomePage/zaraIcon.png';
import BershkaIcon from './../../images/newHomePage/bershkaIcon.png';
import CnAIcon from './../../images/newHomePage/cnaIcon.png';
import FacebookIcon from './../../images/newHomePage/fbIcon.png';
import InstaIcon from './../../images/newHomePage/instaIcon.png';
import TwitterIcon from './../../images/newHomePage/twitterIcon.png';
import ProdIcon1 from './../../images/newHomePage/prod-1.png';
import ProdIcon2 from './../../images/newHomePage/prod-2.png';
import ProdIcon3 from './../../images/newHomePage/prod-3.png';
import ProdIcon4 from './../../images/newHomePage/prod-4.png';
import ProdIcon5 from './../../images/newHomePage/prod-5.png';
import ProdIcon6 from './../../images/newHomePage/prod-6.png';
import ProdIcon7 from './../../images/newHomePage/prod-7.png';
import ProdIcon8 from './../../images/newHomePage/prod-8.png';
import ProdIcon9 from './../../images/newHomePage/prod-9.png';
import ProdIcon10 from './../../images/newHomePage/prod-10.png';
import ProdIcon11 from './../../images/newHomePage/prod-11.png';
import ProdIcon12 from './../../images/newHomePage/prod-12.png';
import ProdIcon13 from './../../images/newHomePage/prod-13.png';
import ProdIcon14 from './../../images/newHomePage/prod-14.png';
import ProdIcon15 from './../../images/newHomePage/prod-15.png';
import ProdIcon16 from './../../images/newHomePage/prod-16.png';
import ProdIcon17 from './../../images/newHomePage/prod-17.png';
import ProdIcon18 from './../../images/newHomePage/prod-18.png';
import ProdIcon19 from './../../images/newHomePage/prod-19.png';
import ProdIcon20 from './../../images/newHomePage/prod-20.png';
import ProdIcon21 from './../../images/newHomePage/prod-21.png';
import ProdIcon22 from './../../images/newHomePage/prod-22.png';
import ProdIcon23 from './../../images/newHomePage/prod-23.png';
import ProdIcon24 from './../../images/newHomePage/prod-24.png';
import Signup_1 from '../../images/faq/signup-1.png';
import ShopingStart_2 from '../../images/faq/shopingStart-2.png';
import ShipmentOnWay_4 from '../../images/faq/payment-3.png';
import shoeBanner from '../../images/shoeBanner.jpg';
import clothBanner from '../../images/clothBanner.jpg';
import Payment_3 from '../../images/faq/shipmentOnWay-4.png';
import flag_tr_small from '../../images/homepage/flag_tr_small.png'
import flag_po_small from '../../images/homepage/flag_po_small.png'
import flag_eu_small from '../../images/homepage/flag_eu_small.png'
import hol from '../../images/homepage/hol.png'
import ger from '../../images/homepage/ger.png'
import fran from '../../images/homepage/fran.png'
import bel from '../../images/homepage/bel.png'
import flag_us_small from '../../images/homepage/flag_us_small.png'
import MetaTags from 'react-meta-tags';
import "./NewHome.scss"
import Carousel from "react-material-ui-carousel";
import { listWarehouse, getHotStoreByCountry, getStoresByCityAndCategory, getHotStoreCountByActive, getTopNHotStore, getActiveCategories, getActiveCountries } from '../../reducers/requestHandler';

const NewHome = ({ setIsRegister, setIsLogin }) => {
    const { userInfo } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const [cIndex, setCIndex] = new useState(0);
    const [allWh, setAllWh] = new useState([]);
    const [banners, setBanners] = new useState([]);
    const [bannersM, setBannersM] = new useState([]);
    const [storeList, setStoreList] = new useState([]);
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const getCIndex = (i, j) => {
        let l = 0;
        if (i > 0) {
            let aWh = allWh?.filter((w) => { if (w.params?.active) { return w } });
            aWh.map((a, ind) => {
                if (ind < i) {
                    l = l + a.params.banner.length;
                } else if (ind == i) {
                    l = l + j + 1;
                }

            })
        }
        return l;
    }
    const handleChange = (cur, prev) => {
        setCIndex(cur);

    };
    const getExt = (name) => {
        let ext = ".png";
        if (name) {
            var lastIndexOf = name.lastIndexOf(".");
            if (lastIndexOf == -1) {
                ext = ".png"; // empty extension
            }
            ext = name.substring(lastIndexOf);
        }
        return ext;
    }
    useEffect(() => {
        window.setTimeout(function () {
            window.document.title = ' משלוחים מחו"ל Red Box ';
        }, 1000)
        getTopNHotStore(userInfo.session, 6, function (response) {
            if (response.misc.CODE === 1) {
                setStoreList(response.data.sort(function (a, b) {
                    return a.orderId - b.orderId;
                }))
            }
        })
        listWarehouse(function (data1) {
            let data = []
            let l = 0;
            data1.data.map((dat, index) => {
                if (index == 0) {
                    l = 0
                }
                else if (index > 0 && dat.params?.banner) {
                    l += dat.params.banner.length;
                }
                dat.params.index = l;
                if (dat.name != 'מערב אירופה' && dat.name != 'טורקיה') {
                    data.push(dat)
                }


            })
            data1.data.map((dat, index) => {
                if (index == 0) {
                    l = 0
                }
                else if (index > 0 && dat.params?.banner) {
                    l += dat.params.banner.length;
                }
                dat.params.index = l;
                if (dat.name === 'מערב אירופה') {
                    data.push(dat)
                }

            })
            data1.data.map((dat, index) => {
                if (index == 0) {
                    l = 0
                }
                else if (index > 0 && dat.params?.banner) {
                    l += dat.params.banner.length;
                }
                dat.params.index = l;
                if (dat.name === 'טורקיה') {
                    data.push(dat)
                }


            })

            // set banners
            var bs = [];
            var bsm = [];
            for (var i = 0; i < data1.data.length; i++) {
                if (data1.data[i].params.active) {
                    var bns = data1.data[i].params.banner;
                    for (var j = 0; j < bns.length; j++) {
                        if (bns[j].isMobile)
                            bsm.push(bns[j]);
                        else
                            bs.push(bns[j]);
                    }
                }
                
            }
            setBanners(bs);
            setBannersM(bsm);
            setAllWh(data);
            
        });
    }, [userInfo]);
    const getFlagByW = (w) => {
        switch (w) {
            case 'מערב אירופה':
                return flag_eu_small
            case 'ארה”ב':
                return flag_us_small
            case 'פולין':
                return flag_po_small
            case 'טורקיה':
                return flag_tr_small
            case 'hol':
                return hol
            case 'ger':
                return ger
            case 'fran':
                return fran
            case 'bel':
                return bel
        }
    }

    return <Paper sx={{ width: '100%', overflow: 'hidden', direction: "rtl" }} className='homePage'>
        <MetaTags id="newHome">
            <title> משלוחים מחו"ל Red Box  </title>
            <link rel="canonical" href="https://redboxparcel.com/" />
            <meta property="og:locale" content="he_IL" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content='משלוחים מחו"ל Red Box' />
            <meta name="keywords" content='משלוחים מחו"ל' />
            <meta name="description" content= 'רד-בוקס פרסל מספקת שירות משלוחים הכולל קבלת כתובות בחו"ל, אריזה מחדש ושילוח עד הבית עם התחייבות למחיר הזול בישראל. החברה שייכת לקבוצת השילוח Total Care Logistics ' />
            <meta property="og:description" content= 'רד-בוקס פרסל מספקת שירות משלוחים הכולל קבלת כתובות בחו"ל, אריזה מחדש ושילוח עד הבית עם התחייבות למחיר הזול בישראל. החברה שייכת לקבוצת השילוח Total Care Logistics ' />
            <meta property="og:url" content="https://redboxparcel.com/" />
            <meta property="og:site_name" content=' משלוחים מחו" ל Red Box  ' />
            <meta property="article:modified_time" content="2023-07-20T12:18:02+00:00" />
            <meta property="og:image" content={RedboxLogo} />
            <meta property="og:image:width" content="150" />
            <meta property="og:image:height" content="37" />
            <meta property="og:image:type" content="image/png" />
        </MetaTags>
        <Box className="page-title-bg" onClick={function () {
            if (userInfo.session) {
                navigate("/accounts")
            } else {
                setIsRegister(true)
            }
        }}>
            {/* <img src={banner_bg} className="home_page_banner_gb" /> */}
            <Box component={'section'} className="carousel home_page_banner_gb" aria-label="Gallery" sx={{ width: "100%", height: "100%" }}>
                {isMobile() ? <Carousel
                    index={cIndex}
                    onChange={handleChange}
                    interval={12000}
                    animation="slide"
                    indicators={false}
                    // stopAutoPlayOnHover
                    swipe
                    className="carousel_container"
                    sx={{ width: "100%", height: "100%" }}
                >
                    {bannersM.map((b, ind) => (<div key={ind} id={`slide${ind}`} className="" style={{ width: "100%", height: "100%" }} >
                        <Box className="" sx={{ width: "100%", height: "100%" }} >
                            <Grid container sx={{ height: { xs: 'auto', sm: '100%' }, direction: 'rtl' }} >
                                <img alt={"banner_" + ind} src={b.link} style={{ width: '100%', height: '100%' }} />

                            </Grid>
                        </Box>
                    </div>))}
                </Carousel> : <Carousel
                    index={cIndex}
                    onChange={handleChange}
                    interval={12000}
                    animation="slide"
                    indicators={false}
                    // stopAutoPlayOnHover
                    swipe
                    className="carousel_container"
                    sx={{ width: "100%", height: "100%" }}
                >
                    {banners.map((b, ind) => (<div key={ind} id={`slide${ind}`} className="" style={{ width: "100%", height: "100%" }} >
                        <Box className="" sx={{ width: "100%", height: "100%" }} >
                            <Grid container sx={{ height: { xs: 'auto', sm: '100%' }, direction: 'rtl' }} >
                                <img alt={"banner_" + ind} src={b.link} style={{ width: '100%', height: '100%' }} />

                            </Grid>
                        </Box>
                    </div>))}
                </Carousel>}
            </Box>

        </Box>
        <Box id="kk" className="container" sx={{ marginBottom: '30px' }}>
            <Box className="box_shadow_with_border" sx={{ paddingTop: { sm: "1px", xs: "10px" }}}>
                <Box className="page_title_flags_content_title">איפה תרצו לקנות?</Box>
                <Box className="page_title_flags_content_text bot_space_20">בחרו מדינה וגלו את כל המבצעים החמים, החנויות השוות ומחירון המשלוחים</Box>
                <Grid container item md={6} sm={8} xs={12} className="page_title_flags_content_flag_div_container">
                    {<>
                        {allWh?.filter((w) => { if (w.params?.active && w.name != 'מערב אירופה' && w.name != 'פולין' /*&& w.name != 'ארה”ב'*/) { return w } }).map((w, i) => (<>
                            <Grid key={i} container item  xs={6} className="page_title_flags_content_flag_div cursor_pointer" onClick={() => {
                                setCIndex(w.params.index);
                            }}>
                                <Grid item container title={`לקניות בארה״ב`} flexDirection="column" alignItems="center">
                                    <span style={{ marginTop: '10px', position: 'relative' }} onClick={() => {

                                        navigate('/store', { state: { id: 1, name: 'store_list', wId: w.id } })
                                    }}>
                                        <img src={getFlagByW(w.name)} className="flag_img" />

                                    </span>
                                    {w.name == 'פולין' && <p className="page_title_flags_content_flag_div_p">{'אנגליה'}</p>}
                                    {w.name != 'פולין' && <p className="page_title_flags_content_flag_div_p">{w.name}</p>}
                                </Grid>
                            </Grid></>
                        ))}
                        <Grid container item xs={6} className="page_title_flags_content_flag_div cursor_pointer" onClick={() => {
                            //setCIndex(w.params.index);
                        }}>
                            <Grid title={`לקניות באיחוד האירופי`} container item flexDirection="column" alignItems="center">
                                <span style={{ marginTop: '10px', position: 'relative' }} onClick={() => {

                                    navigate('/store', { state: { id: 1, name: 'store_list', wId: allWh.find(u => u.name === 'מערב אירופה')?.id } })
                                }}>
                                    <img src={flag_eu_small} className="flag_img" />

                                </span>
                                <p className="page_title_flags_content_flag_div_p">{`מערב אירופה`}</p>
                            </Grid>
                        </Grid>
                        {/*<Grid container item sm={2} xs={6} className="page_title_flags_content_flag_div cursor_pointer" onClick={() => {
                            //setCIndex(w.params.index);
                        }}>
                            <span style={{ marginTop: '10px', position: 'relative' }} onClick={() => {

                                navigate('/store', { state: { id: 1, name: 'store_list', wId: allWh.find(u => u.name === 'מערב אירופה')?.id } })
                            }}>
                                <img src={getFlagByW("fran")} className="flag_img" />

                            </span>
                            <p className="page_title_flags_content_flag_div_p">צרפת</p>
                        </Grid>
                        <Grid container item sm={2} xs={6} className="page_title_flags_content_flag_div cursor_pointer" onClick={() => {
                            //setCIndex(w.params.index);
                        }}>
                            <span style={{ marginTop: '10px', position: 'relative' }} onClick={() => {

                                navigate('/store', { state: { id: 1, name: 'store_list', wId: allWh.find(u => u.name === 'מערב אירופה')?.id } })
                            }}>
                                <img src={getFlagByW("ger")} className="flag_img" />

                            </span>
                            <p className="page_title_flags_content_flag_div_p">גרמניה</p>
                        </Grid>
                        <Grid container item sm={2} xs={6} className="page_title_flags_content_flag_div cursor_pointer" onClick={() => {
                            //setCIndex(w.params.index);
                        }}>
                            <span style={{ marginTop: '10px', position: 'relative' }} onClick={() => {

                                navigate('/store', { state: { id: 1, name: 'store_list', wId: allWh.find(u => u.name === 'מערב אירופה')?.id } })
                            }}>
                                <img src={getFlagByW("hol")} className="flag_img" />

                            </span>
                            <p className="page_title_flags_content_flag_div_p">הולנד</p>
                        </Grid>*/}
                    </>
                    }
                </Grid>
            </Box>
        </Box>
        {/* Header Section */}{/*
        <Grid component={'header'} className='header'>
            <Grid className="container" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', minHeight: '90px' }}>
                <Grid className="brand" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid className="logo">
                        <img src={RedboxLogo} alt="Redbox Parcel" style={{ minWidth: '150px' }} />
                    </Grid>
                    <Button container className='btn btn-outline' sx={{ color: '#000', height: '30px', ml: '50px', outline: 'none', padding: '8px 30px', fontSize: '18px', fontWeight: '700', border: '1px solid #000', borderRadius: '20px' }}>{"התחברות"}</Button>
                </Grid>
                <Grid container dir="rtl" className="navigation">
                    <List container dir="rtl" sx={{ display: 'flex', whiteSpace: 'nowrap', fontSize: '18px', fontWeight: '700' }}>
                        <ListItem>ראשי</ListItem>
                        <ListItem>איך זה עובד? </ListItem>
                        <ListItem>למה איתנו?</ListItem>
                        <ListItem>מחשבון</ListItem>
                        <ListItem>חנויות</ListItem>
                    </List>
                </Grid>
            </Grid>
        </Grid>*/}
        {/* Home Banner section    sx={{ mt: '40px', pb: '40px' }}*/}
        <Grid container component={'section'} className='gray-bg' sx={{ pt: '40px', pb: '20px'}} >
            <Grid container className="container" dir="rtl" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <Grid container item sm={4} xs={12}>
                    <img src={clothBanner} alt="" style={{ borderRadius: "10px", boxShadow:"6px 6px 6px 0px #cbcbcb" }} />
                </Grid>
                <Grid container item sm={4} xs={12} sx={{ xs: { pb: '40px' }, alignItems: 'center', justifyContent: 'center' }}>
                    <Typography component={'h3'} className=' fs22' sx={{ mb: '30px', fontWeight:"bold", width:"90%"}}>
                        {`הכתובות שלכם לקניות בחו״ל`}<br />{` המשלוחים הזולים ביותר ובמחירים`}<br />{`ידועים מראש`}</Typography>
                   {/* <Button onClick={() => { }} container className='btn btn-outline' sx={{ color: '#000', height: '40px', outline: 'none', minWidth: '280px', padding: '8px 30px', fontSize: '18px', fontWeight: '700', border: '1px solid #000', borderRadius: '20px', mb: '30px' }}>
                        {`למחשבון העלויות היחידי שלנו`}
                    </Button>*/}
                    <Typography onClick={() => { navigate("/redboxPricing") }} component={'p'} className=' fs16' sx={{ mb: '20px', textDecoration:"underline",cursor:"pointer" }}> {`למחשבון העלויות היחודי שלנו`}</Typography>
                    
                </Grid>
                <Grid container item sm={4} xs={12}>
                    <img src={shoeBanner} alt="" style={{ borderRadius: "10px", boxShadow: "6px 6px 6px 0px #cbcbcb" }} />
                </Grid>
            </Grid>
        </Grid>
        {/* How It Works? Section */}
        <Grid container component={'section'} sx={{ padding: '40px 0' }}>

            <Grid container className="container" dir="rtl">
                <Grid container item sm={1}></Grid>
                <Grid container item sm={9} direction={'ltr'} sx={{ alignItems: 'center', justifyContent: 'center' }} spacing={2}>

                    <Typography component={'h2'} className='section-title text-right' sx={{ mb: '20px' }}>{`איך זה עובד?`}</Typography>

                    <Grid container item sm={5} sx={{ pt: '10px', pb: '10px', alignContent: 'center', justifyContent: 'center' }}>
                        <img src={Signup_1} alt="" />
                    </Grid>
                    <Grid container item sm={7} sx={{ pt: '10px', pb: '10px', alignContent: 'center' }}>
                        <Typography component={'h3'} sx={{ color: '#000', fontSize: '22px', fontWeight: 'bold', mb: '10px' }}>נרשמים</Typography>
                        <Typography component={'p'} sx={{ color: '#000', fontSize: '18px', width: "100%" }}>{`הרשמו לאתר רד בוקס ללא עלות וקבלו כתובות אישיות`}</Typography>
                        <Typography component={'p'} sx={{ color: '#000', fontSize: '18px' }}>{`איתן ניתן לקנות בחנויות  ארה"ב ואירופה.`}</Typography>
                        {/*<Typography component={'p'} sx={{ color: '#000', fontSize: '18px' }}>{`איתן ניתן לקנות בחנויות בטורקיה, ארה"ב ואירופה.`}</Typography>*/}
                    </Grid>
                    <Grid sm={12} item container style={{ flexDirection: `${isMobile() ? "column-reverse" : ""}` }}>
                        <Grid container item sm={7} sx={{ pt: '10px', pb: '10px', alignContent: 'center' }}>
                            <Typography component={'h3'} sx={{ color: '#000', fontSize: '22px', fontWeight: 'bold', mb: '10px', width: "100%" }}>קונים</Typography>
                            <Typography component={'p'} sx={{ color: '#000', fontSize: '18px' }}>{`עכשיו ניתן לקנות באתרים האהובים עליכם ארה"ב ואירופה !`}</Typography>
                            <Typography component={'p'} sx={{ color: '#000', fontSize: '18px' }}>{`לאחר שממלאים את הסל ועוברים למסך התשלום checkout באתר.`}</Typography>
                            <Typography component={'p'} sx={{ color: '#000', fontSize: '18px' }}>{`הזינו את כתובת המחסן לשם תשלח החבילה ואנו נדאג לקלוט במחסן ולעדכן.`}</Typography>
                        </Grid>
                        <Grid container item sm={5} sx={{ pt: '10px', pb: '10px', alignContent: 'center', justifyContent: 'center' }}>
                            <img src={Payment_3} alt="" />
                        </Grid>
                    </Grid>
                    <Grid container item sm={5} sx={{ pt: '10px', pb: '10px', alignContent: 'center', justifyContent: 'center' }}>
                        <img src={ShopingStart_2} alt="" />
                    </Grid>
                    <Grid container item sm={7} sx={{ pt: '10px', pb: '10px', alignContent: 'center' }}>
                        <Typography component={'h3'} sx={{ color: '#000', fontSize: '22px', fontWeight: 'bold', mb: '10px', width: "100%" }}>{`שרות רד בוקס, עלויות שילוח מוצרים ידועות מראש!
`}<br />{`(והזולות ביותר)`}</Typography>
                        <Typography component={'p'} sx={{ color: '#000', fontSize: '18px', fontWeight: "700",color:"#d70007" }}>{`איך אנחנו עושים את זה?`}</Typography>
                        <Typography component={'p'} sx={{ color: '#000', fontSize: '18px' }}>{ `כשהחבילה מגיעה למחסנים שלנו, הצוות של רד בוקס שוקל ומודד אותה ואז מעדכן אתכם במייל שהיא התקבלה ומה מחיר המשלוח.`}</Typography>
                        {<Typography component={'p'} sx={{ color: '#000', fontSize: '18px' }}>{ `שימו לב, המחירים שלנו לא רק הכי זולים בארץ! הם גם מדויקים לגודל החבילה מכיוון שהם מתומחרים בהתאם למשקל החבילה ולנפח הפיזי שלה במדרגות של 100 גרם ולא ק"ג כמו אצל המתחרים, כך שאתם משלמים בדיוק על החבילה שהזמנתם. `}</Typography>}
                        <Typography component={'p'} sx={{ color: '#000', fontSize: '18px' }}>{ `לחישוב מחיר לפי מידות החבילה היכנסו`}<span className="cursor_pointer" style={{ textDecoration: "underline" }} onClick={() => {
                            navigate("/redboxPricing")
                        }}>{ `למחשבון שלנו`}</span>{`.`}</Typography>
                        {/*<Typography component={'p'} sx={{ color: '#000', fontSize: '18px' }}>{`אנו נעדכן אותכם בהודעת מייל על קליטת החבילה ובקשה להעלות את חשבונית הקנייה ותשלום עבור השילוח עד אליכם.`}</Typography>*/}
                    </Grid>
                    <Grid item container style={{ flexDirection: `${isMobile() ? "column-reverse" : ""}` }}>
                        <Grid container item sm={7} sx={{ pt: '10px', pb: '10px', alignContent: 'center' }}>
                            <Typography component={'h3'} sx={{ color: '#000', fontSize: '22px', fontWeight: 'bold', mb: '10px', width: "100%" }}>{`המשלוח בדרך אליכם....`}</Typography>
                            <Typography component={'p'} sx={{ color: '#000', fontSize: '18px', width: "100%" }}>{`עכשיו,אנו נדאג למשלוח עד אליכם! וכמובן לאורך כל הדרך נדאג לעדכן במיילים לגבי סטאטוס החבילה.`}</Typography>
                            <Typography component={'p'} sx={{ color: '#000', fontSize: '18px', width: "100%" }}>{`(תוכלו גם להתעדכן באופן עצמאי `}<span className="cursor_pointer" style={{ textDecoration: "underline" }} onClick={() => {
                                if (userInfo.session) {
                                    navigate("/accounts")
                                } else {
                                    setIsLogin(true)
                                }

                            }}>באזור האישי</span> {`שלכם באתר).`}</Typography>
                            <Typography component={'p'} sx={{ color: '#000', fontSize: '18px', width: "100%" }}>{`ועוד מעט...תתחדשו😊`}</Typography>
                            <Typography component={'p'} sx={{ color: '#000', fontSize: '18px', width: "100%" }}>{`ובכל עניין, `}<span className="cursor_pointer" style={{ textDecoration: "underline" }} onClick={() => {
                                navigate("/contactUs")
                            }}>{`צרו קשר`}</span></Typography>
                        </Grid>
                        <Grid container item sm={5} sx={{ pt: '10px', pb: '10px', alignContent: 'center', justifyContent: 'center' }}>
                            <img src={ShipmentOnWay_4} alt="" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {/* Why should you order with us? section */}
        <Grid id="whyWorkWithUs" container component={'section'} className='gray-bg' sx={{ pt: '40px'}}>
            <Grid container className="container" >
                <Typography component={'h2'} className='section-title' sx={{ mb: '40px', lineHeight: 1.2 }}>{`למה כדאי להזמין איתנו? `}</Typography>
                <Grid container item sm={2}></Grid>
                <Grid container item sm={8} direction={'ltr'} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Grid container item sm={4} xs={6} sx={{ mb: '20px', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid container sx={{ minHeight: '95px', mb: '10px', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={img3} alt="" style={{ width: 'auto', height: 'auto', maxWidth: '95px', maxHeight: '95px'}} />
                        </Grid>
                        <Typography component={'p'} sx={{ minHeight: '90px', width: '100%', color: '#000', fontSize: '18px', textAlign: 'center', lineHeight: '1.1' }}>{`מרלו"גים בבעלות רד בוקס:`}<br /> {`בארה"ב, מערב אירופה ונתב"ג`} </Typography>
                    </Grid>
                    <Grid container item sm={4} xs={6} sx={{ mb: '20px', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid container sx={{ minHeight: '95px', mb: '10px', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={img2} alt="" style={{ width: 'auto', height: 'auto', maxWidth: '95px', maxHeight: '95px'}} />
                        </Grid>
                        <Typography component={'p'} sx={{ minHeight: '90px', width: '100%', color: '#000', fontSize: '18px', textAlign: 'center', lineHeight: '1.1' }}>{`שירותי שילוח ועמילות מתבצעים`}<br /> { `ע"י רד בוקס ולא במיקור חוץ`} </Typography>
                    </Grid>
                    <Grid container item sm={4} xs={6} sx={{ mb: '20px', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid container sx={{ minHeight: '95px', mb: '10px', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={img1} alt="" sstylex={{ width: 'auto', height: 'auto', maxWidth: '95px', maxHeight: '95px'}} />
                        </Grid>
                        <Typography component={'p'} sx={{ minHeight: '90px', width: '100%', color: '#000', fontSize: '18px', textAlign: 'center', lineHeight: '1.1' }}> { `שירות לקוחות יוצא דופן`}</Typography>
                    </Grid>
                    <Grid container item sm={4} xs={6} sx={{ mb: '20px', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid container sx={{ minHeight: '95px', mb: '10px', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={img4} alt="" style={{ width: 'auto', height: 'auto', maxWidth: '95px', maxHeight: '95px'}} />
                        </Grid>
                        <Typography component={'p'} sx={{ minHeight: '90px', width: '100%', color: '#000', fontSize: '18px', textAlign: 'center', lineHeight: '1.1' }}> { `ניסיון של שני עשורים בשילוח בינלאומי`}</Typography>
                    </Grid>
                    <Grid container item sm={4} xs={6} sx={{ mb: '20px', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid container sx={{ minHeight: '95px', mb: '10px', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={img5} alt="" style={{ width: 'auto', height: 'auto', maxWidth: '95px', maxHeight: '95px'}} />
                        </Grid>
                        <Typography component={'p'} sx={{ minHeight: '90px', width: '100%', color: '#000', fontSize: '18px', textAlign: 'center', lineHeight: '1.1' }}> { `אפשרות להוספת ביטוח חבילות`}</Typography>
                    </Grid>
                    <Grid container item sm={4} xs={6} sx={{ mb: '20px', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid container sx={{ minHeight: '95px', mb: '10px', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={img6} alt="" style={{ width: 'auto', height: 'auto', maxWidth: '95px', maxHeight: '95px'}} />
                        </Grid>
                        <Typography component={'p'} sx={{ minHeight: '90px', width: '100%', color: '#000', fontSize: '18px', textAlign: 'center', lineHeight: '1.1' }}> { `מחירי השילוח הזולים בישראל`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {/* Products Section */}
        <Grid container component={'section'} className='prod-section' sx={{ pt: '40px', pb: '40px' }}>
            <Grid container className="container" >
                <Typography component={'h2'} className='section-title' sx={{ mb: '10px' }}>{`מחשבון משקלים`}</Typography>
                <Typography component={'p'} className='section-descrition' sx={{ mb: '30px' }}>{`מחשבון ייחודי לפי משקל  מוצר! רק ברד בוקס מחיר השילוח ידוע מראש!`}</Typography>
                {/*<Typography component={'h3'} className='section-secTitle' sx={{ mb: '40px' }}>{`מחשבון משקלים של מוצרים פופולאריים`}</Typography>
                <Grid container className='prod-wrapper'>
                    <Grid container item sm={2}></Grid>
                    <Grid container item sm={8} direction={'ltr'}  >
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon1} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'מעיל'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon2} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'טי שירט'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon3} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'חולצה'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon4} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'מכנסיים'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon5} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'מגפיים'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon6} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'נעליים'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon7} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'חגורה'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon8} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'כובע בייסול'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon9} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'גרביים'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon10} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'שמלה'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon11} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'סוודר'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                        <Grid container item className='prod-box cursor_pointer' onClick={() => { navigate("/redboxPricing") }}>
                            <img src={ProdIcon12} alt='' className='prod-img' />
                            <Typography component={'p'} className='prod-title'>{'סווטשירט'}</Typography>
                            <img src={ProdDropdown} alt='' className='prod-dropdown' />
                        </Grid>
                    </Grid>
                </Grid>*/}
                <Grid container sx={{ alignContent: 'center', justifyContent: 'center', mt: '10px', mb: '30px' }}>
                    <Button className='btn btn-primary btn-red' onClick={() => { navigate("/redboxPricing") }}>{'חשב כמה יעלה לי המשלוח'}</Button>
                </Grid>
            </Grid>
        </Grid>
        {/* Shops Section */}
        <Grid container component={'section'} className='gray-bg' sx={{ pt: '40px', pb: '40px' }}>
            <Grid container className={'recommended-shops-title fullwidth'} sx={{ width: 'auto', mb: '40px' }}>
                <Typography component={'h2'} className='section-title' sx={{ width: 'auto !important', mr: '10px' }}>{'חנויות מומלצות'}</Typography>
                {/*<img src={ShoppingCouple} alt='' />*/}
            </Grid>
            {/*<List container className='shops-categary fullwidth rtl' sx={{mb: '30px'}}>
                <ListItem>ביגוד</ListItem>
                <ListItem>קוסמטיקה</ListItem>
                <ListItem>ספורט</ListItem>
                <ListItem>נעליים</ListItem>
                <ListItem>ילדים</ListItem>
                <ListItem>אלקטרוניק</ListItem>
            </List>*/}
            <List container className='fullwidth shop-list' sx={{ mb: '40px' }}>
                {storeList.map((st, ind) => (<ListItem className="cursor_pointer" onClick={() => {
                    window.open(st.link, "_blank");
                }}><img src={`/images/shops/${st.id}_logo${getExt(st.params?.fileName_logo)}`} alt="" /></ListItem>))}
                {/*<ListItem><img src={MACIcon} alt="" /></ListItem>
                <ListItem><img src={ZaraIcon} alt="" /></ListItem>
                <ListItem><img src={BershkaIcon} alt="" /></ListItem>
                <ListItem><img src={CnAIcon} alt="" /></ListItem>*/}
            </List>
        </Grid>
        {/* Footer Section */}
        {/*<Grid container component={'section'} className='footer-section darkgray-bg rtl' sx={{ pt: '50px', pb: '50px' }}>
            <Grid container className="container" >
                <Grid container item sm={3} xs={6} className='footer-col'>
                    <Typography component={'h3'} className='col-title fs18'>השירותים שלנו</Typography>
                    <List className='links-list'>
                        <ListItem><a title="" href={'#'}> שילוח אווירי </a></ListItem>
                        <ListItem><a title="" href={'#'}> ניהול פרויקט שילוח </a></ListItem>
                        <ListItem><a title="" href={'#'}> עמילות מכס </a></ListItem>
                        <ListItem><a title="" href={'#'}> Total Care Logistics </a></ListItem>
                        <ListItem><a title="" href={'#'}> הובלה ימית </a></ListItem>
                        <ListItem><a title="" href={'#'}> שילוח צד ג' ושילוח אקספרס </a></ListItem>
                    </List>
                </Grid>
                <Grid container item sm={3} xs={6} className='footer-col'>
                    <Typography component={'h3'} className='col-title fs18'>קישורים</Typography>
                    <List className='links-list'>
                        <ListItem><a title="" href={'#'}> מפת האתר </a></ListItem>
                        <ListItem><a title="" href={'#'}> שירות לקוחות </a></ListItem>
                        <ListItem><a title="" href={'#'}> צור קשר </a></ListItem>
                    </List>
                </Grid>
                <Grid container item sm={3} xs={6} className='footer-col'>
                    <Typography component={'h3'} className='col-title fs18'>עקבו אחרינו</Typography>
                    <List className='social-links'>
                        <ListItem><a title="Facebook" href={'#'}><img src={FacebookIcon} alt='' /></a></ListItem>
                        <ListItem><a title="Instagram" href={'#'}><img src={InstaIcon} alt='' /></a></ListItem>
                        <ListItem><a title="Twitter" href={'#'}><img src={TwitterIcon} alt='' /></a></ListItem>
                    </List>
                </Grid>
                <Grid container item sm={3} xs={6} className='footer-col'>
                    <Typography component={'h3'} className='col-title fs18'>ליצירת קשר</Typography>
                    <List className='address-list'>
                        <ListItem> Total Care Logistics Ltd <br /> 
                        רחוב הבנים 14 הוד השרון מיקוד <br />
                         4526821 </ListItem>
                    </List>
                    <List className='contact-list'>
                        <ListItem><a title="09-7443830" href={'tel:09-7443830'}>טל: 09-7443830</a></ListItem>
                        <ListItem><a title="09-7443844" href={'fax:09-7443844'}>פַקס: 09-7443844</a></ListItem>
                    </List>
                </Grid>
            </Grid>
        </Grid>*/}
    </Paper>
};

export default NewHome;