/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Paper, Grid, Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import './FAQS.scss';
import { getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import account_banner from '../../images/homepage/account_banner.png';
import userPng from '../../images/homepage/user.png';
import ActionButton from '../Common/ActionButton';
import address from '../../images/homepage/address.png'
import tracking from '../../images/homepage/tracking.png'
import calculator from '../../images/homepage/calculator.png'
import ShopingOne from '../../images/faq/one-man.png';
import ShopingTwo from '../../images/faq/man-woman-shipping.png';
import BlueBoxGirl from '../../images/faq/blueBoxGirl.png';
import Stopwatch from '../../images/faq/stopwatch.svg';
import Signup_1 from '../../images/faq/signup-1.png';
import ShopingStart_2 from '../../images/faq/shopingStart-2.png';
import  ShipmentOnWay_4 from '../../images/faq/payment-3.png';
import Payment_3 from '../../images/faq/shipmentOnWay-4.png';
import pdf from '../../files/approval.pdf';
import queryString from 'query-string';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { get_params } from '../../reducers/requestHandler';
import MetaTags from 'react-meta-tags';

export const FAQS = (props) => {
    const { setActiveScreenInreg, setIsRegister, setIsLogin } = props;
    const { userInfo, innerContent } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    //const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));

    const vnavigate = useNavigate();
    const navigate = function (url) {
        vnavigate(url);
        window.scrollTo(0,0);
    }
    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [isEnable, setEnabled] = new useState(true);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [hasError, setHasError] = new useState(false);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);
    const [isSubFilter, setIsSubFilter] = useState(false);
    
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const [expanded, setExpanded] = useState('');
    const handleChange = (panel) => (isExpanded) => {
        if (expanded === panel) {
            setExpanded(false);
        }
        else {
            setExpanded(isExpanded ? panel : false);
        }

    };
    const location = useLocation();
    useEffect(() => {
        
        if (location?.state?.name == "forbidden_for_flight") {
            document.getElementById('forbidden_for_flight').scrollIntoView();
            handleChange('29');
        }
        if (location?.state?.name == "forbidden_for_import") {
            document.getElementById('forbidden_for_import').scrollIntoView();
            handleChange('30');
        }
        if (location?.state?.name == "from_registration") {
            document.getElementById('from_registration').scrollIntoView();
        }
        if (location?.state?.name == "fromRightContentBelowPricing") {
            document.getElementById('more_info_41').scrollIntoView(); 
            //document.getElementById('fromRightContentBelowPricing').scrollIntoView();
        }
        if (location?.state?.name == "faqList") {
            document.getElementById('faqList').scrollIntoView();
        }
        var action = get_params("action")
        if (action === "from_registration") {
            document.getElementById('from_registration').scrollIntoView();
        }
        if (action === "forbidden_for_flight") {
            document.getElementById('forbidden_for_flight').scrollIntoView();
            handleChange('29');
        }
        if (action === "forbidden_for_import") {
            document.getElementById('forbidden_for_import').scrollIntoView();
            handleChange('30');
        }
        let searchParams = queryString.parse(location.search)
        try {
            console.log(searchParams.id)
            document.getElementById(searchParams.id).scrollIntoView();
            window.setTimeout(function () {
                document.getElementById(searchParams.id).scrollIntoView();
            }, 500)
           
            if (searchParams.id == "consolidationlinkTarget")
                setExpanded('246');
        } catch (e) { }
        // }
    }, [userInfo]);
    return (
        <>
            <MetaTags id="metaFAQ">
                <title>הכל על השירות שלנו</title>
                <meta id="meta-description" name="FAQS" content='הכל על השירות שלנו' />
                <meta id="meta-description-title" property="og:title" content="Redbox parcel" />
            </MetaTags>
            <Box>
                <Box component={'section'} className="faq-section" style={{ padding: '30px 0' }}>
                    {!isMobile() && <Box className="container">
                        
                        <Grid container className='fillter-tabs'>
                            <ul className='tabs-list'>
                                <li className={!isSubFilter ? 'active':''} ><a href="#">איך זה עובד?</a></li>
                                <li onClick={() => {
                                    document.getElementById('faqList').scrollIntoView();
                                }}><a >שאלות ותשובות</a></li>
                                {/*<li className={isSubFilter ? 'active' : ''} onClick={() => {  document.getElementById('more_info').scrollIntoView(); }}><a >מדריכים</a></li>*/}
                            </ul>
                        </Grid>
                        

                        <Typography component={'h1'} className='section-title mb-60'>איך עובד תהליך ההרשמה והרכישה ב- RedBox?</Typography>
                        {/* Step 1 */}
                        <Grid container className='box-wrapper'>
                            <Grid item sm={5} className='blue-rounded-box redbox-one'>
                                <img src={Signup_1} alt='' />
                            </Grid>
                            <Grid container item sm={8} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={2} sx={{ alignItems: 'flex-start'}}>
                                    <Typography className='step_no' component={'h2'}>{/*1*/}</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <Typography className='step_title' component={'h2'}>נרשמים</Typography>
                                    <Typography className='step_desc'>{`בתהליך הרשמה קצר מצטרפים לשירות ומקבלים כתובת אישית בחו"ל בחינם.`}</Typography>
                                    <ActionButton textLabel={`הירשמו בחינם`} className='underline-btn red_text' onClick={() => {
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setActiveScreenInreg('Registeration'); setIsRegister(true);
                                        }
                                    }} />
                                </Grid>
                                {/*<Grid item sm={2}>
                                    <Grid container className='stopwatch'>
                                        <img src={Stopwatch} alt='' />
                                        {`3 דקות`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                        </Grid>
                        {/* Step 2 */}
                        <Grid container className='box-wrapper dir-rtl'>
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={1} sx={{ alignItems: 'flex-start'}}>
                                    <Typography className='step_no' component={'h2'}>{/*2*/}</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <Typography className='step_title' component={'h2'}>{`מתחילים בקניות`}</Typography>
                                    <Typography className='step_desc'>{`קונים בחנות המועדפת עליכם ובהשלמת הרכישה מזינים את הכתובת האישית שקיבלתם ככתובת המשלוח.`}</Typography>
                                    <Typography className='anchor link-text cursor_pointer' onClick={() => { navigate("/store")}}>{`לרשימת החנויות המומלצות`}{` >`}</Typography>
                                </Grid>
                                {/*<Grid item sm={3}>
                                    <Grid container className='stopwatch align-right'>
                                        <img src={Stopwatch} alt='' />
                                        {`תלוי בכם`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                            <Grid item sm={5} className='blue-rounded-box redbox-two'>
                                <img src={ShopingStart_2} alt='' />
                            </Grid>
                        </Grid>
                        {/* Step 3 */}
                        <Grid container className='box-wrapper'>
                            <Grid item sm={5} className='blue-rounded-box redbox-three'>
                                <img src={Payment_3} alt='' />
                            </Grid>
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={3} sx={{ alignItems: 'flex-start'}}>
                                    <Typography className='step_no' component={'h2'}>{/*3*/}</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <Typography className='step_title' component={'h2'}>{`משלמים`}</Typography>
                                    <Typography className='step_desc'>{`כשהחבילה מגיעה אלינו תקבלו הודעה ובכמה שלבים מהירים תבחרו את אופן המשלוח המתאים לכם ותשלימו את התשלום.`}</Typography>
                                    
                                </Grid>
                                {/*<Grid item sm={2}>
                                    <Grid container className='stopwatch'>
                                        <img src={Stopwatch} alt='' />
                                        {`4 דקות`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                        </Grid>
                        {/* Step 4 */}
                        <Grid container className='box-wrapper dir-rtl'>
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={1} sx={{ alignItems: 'flex-start'}}>
                                    <Typography className='step_no' component={'h2'}>{/*4*/}</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <Typography className='step_title' component={'h2'}>{`המשלוח בדרך אליכם`}</Typography>
                                    <Typography className='step_desc'>{`אנו נדאג לקליטת החבילה בארץ ואתם תהנו ממשלוח שיגיע אליכם עד הבית!`}</Typography>
                                    
                                </Grid>
                                {/*<Grid item sm={3}>
                                    <Grid container className='stopwatch align-right'>
                                        <img src={Stopwatch} alt='' />
                                        {`עד 5 ימי עסקים`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                            <Grid item sm={5} className='blue-rounded-box redbox-four' >
                                <img src={ShipmentOnWay_4} alt=''  />
                            </Grid>
                            
                        </Grid>
                        <span id="faqList"></span>
                    </Box>}
                    {isMobile() && < Box className="container">
                        
                        <Grid container className='fillter-tabs'>
                            <ul className='tabs-list'>
                                <li className={!isSubFilter ? 'active' : ''} ><a href="#">איך זה עובד?</a></li>
                                <li onClick={() => {
                                    document.getElementById('faqList').scrollIntoView();
                                }}><a >שאלות ותשובות</a></li>
                                {/*<li className={isSubFilter ? 'active' : ''} onClick={() => { document.getElementById('more_info').scrollIntoView(); }}><a >מדריכים</a></li>*/}
                            </ul>
                        </Grid>


                        <Typography component={'h1'} className='section-title mb-60'>איך עובד תהליך ההרשמה והרכישה ב- RedBox?</Typography>
                        {/* Step 1 */}
                        <Grid container className='box-wrapper' flexDirection="column" alignItems="center">
                           
                            <Grid container item sm={8} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={2} sx={{ alignItems: 'flex-start' }}>
                                    <Typography className='step_no' component={'h2'}>1</Typography>
                                </Grid>
                                <Grid item sm={8} container justifyContent="center">
                                    <Typography className='step_title' component={'h2'}>נרשמים</Typography>
                                    <Typography className='step_desc'>{`בתהליך הרשמה קצר מצטרפים לשירות ומקבלים כתובת אישית בחו"ל בחינם.`}</Typography>
                                    <ActionButton textLabel={`הירשמו בחינם`} className='underline-btn red_text' onClick={() => {
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setActiveScreenInreg('Registeration'); setIsRegister(true);
                                        }
                                    }} />
                                </Grid>
                                {/*<Grid item sm={2}>
                                    <Grid container className='stopwatch'>
                                        <img src={Stopwatch} alt='' />
                                        {`3 דקות`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                            <Grid item sm={5} >
                                <img src={Signup_1} alt='' />
                            </Grid>
                        </Grid>
                        {/* Step 2 */}
                        <Grid container className='box-wrapper dir-rtl' flexDirection="column" alignItems="center">
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={1} sx={{ alignItems: 'flex-start' }}>
                                    <Typography className='step_no' component={'h2'}>2</Typography>
                                </Grid>
                                <Grid item sm={8} container justifyContent="center">
                                    <Typography className='step_title' component={'h2'}>{`מתחילים בקניות`}</Typography>
                                    <Typography className='step_desc'>{`קונים בחנות המועדפת עליכם ובהשלמת הרכישה מזינים את הכתובת האישית שקיבלתם ככתובת המשלוח.`}</Typography>
                                    <Typography className='anchor link-text cursor_pointer' onClick={() => { navigate("/store") }}>{`לרשימת החנויות המומלצות`}{` >`}</Typography>
                                </Grid>
                                {/*<Grid item sm={3}>
                                    <Grid container className='stopwatch align-right'>
                                        <img src={Stopwatch} alt='' />
                                        {`תלוי בכם`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                            <Grid item sm={5} >
                                <img src={ShopingStart_2} alt='' />
                            </Grid>
                        </Grid>
                        {/* Step 3 */}
                        <Grid container className='box-wrapper'  alignItems="center">
                            
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={3} sx={{ alignItems: 'flex-start' }}>
                                    <Typography className='step_no' component={'h2'}>3</Typography>
                                </Grid>
                                <Grid item sm={8} container justifyContent="center">
                                    <Typography className='step_title' component={'h2'}>{`משלמים`}</Typography>
                                    <Typography className='step_desc'>{`כשהחבילה מגיעה אלינו תקבלו הודעה ובכמה שלבים מהירים תבחרו את אופן המשלוח המתאים לכם ותשלימו את התשלום.`}</Typography>

                                </Grid>
                                {/*<Grid item sm={2}>
                                    <Grid container className='stopwatch'>
                                        <img src={Stopwatch} alt='' />
                                        {`4 דקות`}
                                    </Grid>
                                </Grid>*/}
                            </Grid>
                            <Grid item sm={5} >
                                <img src={Payment_3} alt='' />
                            </Grid>
                        </Grid>
                        {/* Step 4 */}
                        <Grid container className='box-wrapper dir-rtl' flexDirection="column" alignItems="center">
                            <Grid container item sm={9} className='darkblue-rounded-box white_box shadow_box dir-rtl'>
                                <Grid container item sm={1} sx={{ alignItems: 'flex-start' }}>
                                    <Typography className='step_no' component={'h2'}>4</Typography>
                                </Grid>
                                <Grid item sm={8} container justifyContent="center">
                                    <Typography className='step_title' component={'h2'}>{`המשלוח בדרך אליכם`}</Typography>
                                    <Typography className='step_desc'>{`אנו נדאג לקליטת החבילה בארץ ואתם תהנו ממשלוח שיגיע אליכם עד הבית!`}</Typography>

                                </Grid>
                            </Grid>
                            <Grid item sm={5}  >
                                <img src={ShipmentOnWay_4} alt='' />
                            </Grid>

                        </Grid>
                        <span id="faqList"></span>
                    </Box>}
                </Box>

                <Box component={'section'} className="faq-section" style={{ padding: '30px 0' }} >
                    <Box className="container">
                        <Typography  component={'h1'} className='section-title text-right'>שאלות ותשובות נפוצות</Typography>
                        <Grid container className='faq-wrapper' sx={{ justifyContent: 'space-between' }}>
                            <Grid container item sm={2.2}>
                                <Grid container className="row flex-rtl" sx={{ justifyContent: 'space-between', maxWidth: { sm: '90%' }, margin: { xs: '0' } }}>
                                    <Grid item className='blue-box cursor_pointer' onClick={() => {
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setActiveScreenInreg('Registeration'); setIsRegister(true);
                                        }
                                    }}>
                                        <Box className="min_hieght_set set_mid">
                                            <img src={userPng} className="how_to_images" />
                                        </Box>
                                        <Box className='how_to_title' >
                                            <Button className="with_arrow_icon">{`להרשמה מהירה`}</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item className='blue-box cursor_pointer' onClick={() => {
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setIsLogin(true);
                                        }
                                    }}>
                                        <Box className="min_hieght_set set_mid">
                                            <img src={tracking} className="how_to_images" />
                                        </Box>
                                        <Box className='how_to_title'>
                                            <Button className="with_arrow_icon">{`מעקב משלוחים`}</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item className='blue-box cursor_pointer' onClick={() => {
                                        if (userInfo.session) {
                                            navigate("/accounts")
                                        } else {
                                            setIsLogin(true);
                                        }
                                    }}>
                                        <Box className="min_hieght_set set_mid">
                                            <img src={address} className="how_to_images" />
                                        </Box>
                                        <Box className='how_to_title'>
                                            <Button className="with_arrow_icon">{`הכתובות שלי`}</Button>
                                        </Box>
                                    </Grid>
                                    <Grid item className='blue-box cursor_pointer' onClick={() => {
                                        navigate("/redboxPricing")
                                    }}>
                                        <Box className="min_hieght_set set_mid">
                                            <img src={calculator} className="how_to_images" />
                                        </Box>
                                        <Box className='how_to_title'>
                                            <Button className="with_arrow_icon">{`מחשבון משלוחים`}</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item sm={9.8} className="shadow_box" >
                                <Grid container item flexDirection="row-reverse">
                                    <Grid item container flexDirection="column">
                                        <h2 style={{ direction: "rtl", fontSize: "28px", fontWeight: "bold", color: "black", textDecoration: "underline" }}>{`שעות הפעילות שלנו:`}</h2>
                                    </Grid>
                                    <Accordion className='accordion' expanded={expanded === '1'} onChange={handleChange('1')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '1' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מה שעות פעילות של המחסנים בחו"ל ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`המחסנים בארה"ב ובהולנד פתוחים בימים ב' עד ו' בין השעות 9:00-16:00 (זמן מקומי).  מומלץ לתאם את המסירה לזמנים אלה.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '2'} onChange={handleChange('2')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '2' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `מה שעות הפעילות של שירות הלקוחות בארץ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `שירות הלקוחות שלנו זמין בימים א'-ה' בין השעות בין השעות 9:00-16:00.  פניות מחוץ לשעות הפעילות מומלץ לעשות באמצעות המייל בלבד.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px", textDecoration: "underline" }}>{`מחירי שילוח, מיסים ואגרות מדינה:`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '3'} onChange={handleChange('3')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '3' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `כיצד מחושב התשלום עבור משלוח החבילה והשחרור מהמכס?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    <b>{ `מחירי המשלוח נקבעים על פי המשקל הפיזי או המשקל הנפחי (נפח החבילה),  הגבוה מבין השניים.`}</b><br/>
                                                    { `משקל נפחי מחושב על פי הנוסחא הבא: אורך (בס"מ)X רוחב (בס"מ)X גובה (בס"מ) חלקי 5000 = המשקל הנפחי (נפח מתורגם לק"ג). אם  המידות מופיעות באינצ'ים יש לחלק את מכפלת המידות ב139.  שימו לב כי המידות הן המידות החיצוניות של החבילה ששלח המוכר, ולא מידות המוצר`} <br/>
                                                    <b>{ `בנוסף, המחיר הסופי מושפע ממסים ואגרות מדינה ונקבע גם לפי עלות וגם לפי סוג  המוצרים שהזמנתם. `}</b> <br/>
                                                    {`תוכלו לקבל הצעת מחיר מפורטת`}<span ><a href="/redboxPricing">{`במחירון`}</a></span> { `שבאתר. יש לבחור במדינה ממנה מתבצע השילוח, ובמחשבון באופציה של חישוב מפורט.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '301'} onChange={handleChange('301')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '301' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`איך מחושב המע"מ עבור מספר חבילות מאותו ספק ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>

                                                    {`רשויות המס מתייחסות לכל החבילות שלך, המגיעות לארץ יחד מאותו ספק, כרכישה אחת ויגבו מס על פי הסכום הכולל.  כלומר: אם סכום המוצרים בכל בחבילות שלך מאותו ספק  יעלה על 75$ יגבה עבור החבילות מע"מ, גם אם כל חבילה בנפרד אינה מגיעה ל75$.`}<br />
                                                    {`על מנת לוודא שכל חבילה מגיעה בטיסה נפרדת ניתן לשלם עבור שילוח החבילות בהפרש של שבוע בין חבילה לחבילה.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                   {/* <Accordion className='accordion' expanded={expanded === '4'} onChange={handleChange('4')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '4' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם הצהרה על עלות המוצר כוללת הובלה ומסים מקומיים?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `כשמצהירים למכס הישראלי על ערך המוצרים בעת התשלום, או כאשר בודקים את עלות השילוח והמסים במחשבון  יש להצהיר על ערך המוצרים על פי הפירוט הבא:`}
                                                </Typography>
                                                <ul>
                                                    <li>{ `מוצרים שעלו עד 75$ - יש לרשום את ערך המוצר בלבד (לא כולל מסים והובלה מקומית)`}</li>
                                                    <li>{`מוצרים בעלות של 75$ ומעלה - יש לרשום את עלות המוצר ועלות ההובלה המקומית בחו"ל, כפי שרשומה בחשבונית. אין צורך להוסיף את המסים המקומיים.`}</li>
                                                    <li><span style={{ textDecoration: "underline" }}>{`בחבילה מאוחדת `}</span>{` יש להצהיר על הסכום הכולל של כל החשבוניות הנכללות באיחוד (כלומר, מסכמים את כל החשבוניות יחד. אם הסכום של החשבוניות עולה על 75$, ידרש תשלום מס גם אם ערך כל אחת מהחבילות המאוחדות היה נמוך מ75$).`}</li>
                                                </ul>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>*/}
                                    <Accordion className='accordion' expanded={expanded === '5'} onChange={handleChange('5')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '5' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`עד איזה סכום לא משלמים מע"מ ומכס?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `על פי חוקי המכס בישראל, יש מדרגות פטור ממיסוי ביבוא אישי :`}<br/>
                                                    { `עד 75$ - פטור לחלוטין ממיסי יבוא – כלומר, חבילות בעלות של עד 74.99$ אינן חייבת במע"מ, מכס או מסי קניה (הפטור אינו חל על מוצרי טבק, כוהל, משקאות משכרים וסיגריות אלקטרוניות .). כלומר,`}<br/>
                                                    { `75$-500$ - פטור מתשלום מכס בלבד, חייב בתשלום מע"מ + מס קניה . כלומר, יש מע"מ גם על המוצרים וגם על המשלוח, ויתכן מס קניה (תלוי במוצרים שקניתם).`}<br/>
                                                    { `501$-1000$ - חייב במיסים מלאים .`}<br/>
                                                    { `מעל 1000$ - נחשב כיבוא מסחרי .`}<br/>
                                                    { `מעל 100$ תחול על המשלוח אגרת הצהרת יבוא (אגרת רשימון) כמפורט בדף המחירון באתר.`}<br/>
                                                    {`למידע נוסף לגבי מיסוי בייבוא אישי לחצו כאן.`}<br />

                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '6'} onChange={handleChange('6')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '6' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `מתי ידרשו אגרות נוספות עבור יבוא החבילה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    <ul>
                                                        <li>{ `1.	במקרה של עיכוב בשחרור ממכס בשל מחסור בחשבונית, אי התאמה בין החשבונית המצורפת לתכולת החבילה,  אישורי יבוא ו/או בדיקה פיזית של המכס, תחול על המשלוח עמלת טיפול פרטני על סך 45$  ובנוסף אגרת אחסנה לפי חיובי מסוף המטענים בנתב"ג.`}</li>
                                                        <li>{ `2.	כאשר מדובר במוצרים אשר היבוא שלהם לארץ דורש אישורים מיוחדים ממשרד התחבורה, התקשורת, הבריאות או כל גורם ממשלתי אחר, יחול על הלקוח חיוב נוסף בסך 45$ עבור הוצאת האישור מהמשרד הממשלתי. הלקוח יוכל להוציא את האישור עצמאית ולהעבירו לרדבוקס. במידה ולא יסופק האישור הנכון , השחרור מן המכס עלול להתעכב ולגרור עלויות נוספות כמפורט בסעיף 1. (את התשלום הנוסף למכס יש להסדיר עד 90 ימים ,לאחר פרק זמן זה החבילה מוגדרת כחבילה נטושה ע"י המכס)`}</li>
                                                        <li>{ `3.	ביבוא אישי של מעל ל1000$ נדרש שיחרור פרטני מהמכס של החבילה ויתכנו עלויות נוספות כמפורט בסעיף 1. `}</li>
                                                        <li>{ `4.	טיפול ביבוא מסחרי והוצאת רשימון יבוא כרוכים בדמי טיפול על סך .45$ על יבוא מסחרי יחולו  עלויות נוספות לאחר הגעת החבילה לארץ:  תשלום מע"מ, עמלת שיחרור בסך 45$ , אחסנה לפי חיובי מסוף המטענים ודמי טיפול. יש להצהיר בעת ההרשמה לאתר על היותך לקוח מסחרי. `}</li>
                                                    </ul>
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '61'} onChange={handleChange('61')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '61' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מי מבצע את שחרור החבילה מהמכס?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`רד-בוקס פרסל הינה סוכן מכס מורשה ומבצעת גם את שחרור החבילה מהמכס עבור לקוחותיה.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '7'} onChange={handleChange('7')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '7' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `מה נכלל בערך המוצרים לצורך הצהרה למכס?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `כאשר מצהירים למכס הישראלי על ערך המוצרים בעת התשלום, או כאשר בודקים את עלות השילוח והמסים במחשבון שבאתר מצהירים על "ערך המוצר" על פי הפירוט הבא:`}
                                                </Typography>
                                                <ul>
                                                    <li>{ `•	מוצרים שעלו עד 75$ - יש לרשום את ערך המוצר בלבד (לא כולל מסים והובלה מקומית)`}</li>
                                                    <li>{ `•	מוצרים בעלות של 75$ ומעלה - יש לרשום את עלות המוצר ועלות ההובלה המקומית בחו"ל, כפי שרשומה בחשבונית. אין צורך להוסיף את המסים המקומיים.`}</li>
                                                    <li>{ `•	בחבילה מאוחדת יש לרשום את הסכום הכולל של כל החשבוניות הנכללות באיחוד (כלומר, מסכמים את כל החשבוניות יחד. אם הסכום של כל החשבוניות עולה על 75$, ידרש תשלום מס גם אם ערך כל אחת מהחבילות המאוחדות היה נמוך מ75$).`}</li>
                                                </ul>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '71'} onChange={handleChange('71')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '71' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `איך אפשר לקבל קופוני הנחה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {  `אנחנו מעדכנים על הקופונים בדיוורים שלנו במייל. מומלץ לבדוק האם אישרת דיוורים (אם לא ניתן לשנות זאת באזור האישי שלך באתר), או האם המיילים שלנו הגיעו לתיבת ספאם או לתיבת קידום מכירות.`}
                                                    {  `אם המיילים הגיעו לשם, מומלץ להעביר את המייל לדואר נכנס ולשמור אותנו באנשי הקשר שלך`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '8'} onChange={handleChange('8')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '8' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אוכל לשלוח דרככם חבילה אם אין לי חשבונית?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`ביבוא לארץ, נדרש להציג למכס חשבונית קנייה. עם זאת, יש מקרים בהם הלקוחות מעוניינים להביא מוצרים יד שניה, מתנות, או פיצוי מהמוכר על קנייה קודמת, וכד'. במקרה כזה יש למלא ולצרף בעת התשלום `}<span><a href="/CustomsForm130.pdf">{`תצהיר יבואן .`}</a></span>{ `ולהצהיר בטופס התשלום על הערך של המתנה שקיבלת.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '9'} onChange={handleChange('9')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '9' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מהם המסים המקומיים בניו ג'רזי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`המס המקומי בניו ג''רזי עומד על  6.625%`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '10'} onChange={handleChange('10')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '10' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם יש עמלת המרה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `מחירי השירותים שרד בוקס מציגה באתר נקובים בדולר ארה"ב.`}<br />
                                                    { `ההמרה לש"ח מבוצעת בהתאם לשער הדולר היציג שנקבע על ידי בנק ישראל ביום העסקים שקדם ליום ההזמנה בתוספת שלושה וחצי אחוזים לכיסוי עמלות ההמרה והעברות בין הבנקים ועלויות העמסה על שער המכס ברשימון.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>

                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px", textDecoration: "underline" }}>{`תהליך השילוח של החבילה מחו"ל לארץ:`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '11'} onChange={handleChange('11')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '11' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`בתוך כמה זמן מרגע ששילמתי אוכל לקבל את החבילה? `}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `זמן ההגעה כיום הוא כ-14 עד21 ימי עסקים מרגע התשלום על המשלוח.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '12'} onChange={handleChange('12')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '12' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`איך המוצרים נשלחים לישראל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`שילוח החבילה נעשה באמצעות הטסה אווירית בלבד.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '13'} onChange={handleChange('13')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '13' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`תוך כמה זמן אוכל לראות את החבילה באזור האישי שלי באתר? `}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`חבילה נקלטת תוך 2-3 ימי עסקים מרגע קבלת הודעת המסירה מהספק על הגעתה למרכז הלוגיסטי שלנו. ברגע שהחבילה תקלט, תקבל הודעת מייל עם קישור לאזור האישי באתר, שם תתבקש להעלות חשבונית, לבצע תשלום ולבחור האם אתה רוצה משלוח עד אלייך או לנק' איסוף לבחירתך.`}<br />
                                                    {`אם לאחר 3 ימי עסקים החבילה שלך לא נקלטה, יש לפנות לשירות לקוחות בצירוף צילום מסך של הודעת המסירה מחברת ההובלה המקומית, כולל מספר המעקב המופיע אצלם.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '13'} onChange={handleChange('13')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '13' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`איך ניתן לעקוב אחר החבילה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`עם קליטת החבילה במחסן ישלח לך מייל עם מספר המעקב של החבילה אצלנו. מרגע זה ניתן אחר החבילה באזור האישי שלך באתר של רד-בוקס פרסל. בנוסף נשלח לך מייל עם התקדמות החבילה בכל אחד מהשלבים. כאשר החבילה תועבר לחברת ההפצה ישלחו לך עדכונים בSMS מחברת ההפצה.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '14'} onChange={handleChange('14')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '14' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אוכל לעקוב אחר החבילה שלי באתרים גלובאליים?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`אנחנו חברת שילוח ועמילות מכס, ומביאים את החבילות שלך ישירות דרך חברות התעופה והמכס ולא דרך גורם שלישי.`}<br />
                                                    {`לכן המעקב על החבילה יופיע רק באזור האישי שלך באתר שלנו. ברגע נחיתת החבילה בארץ ושחרורה מהמכס, ישלח לך עדכון כי החבילה הועברה לחברת בר הפצה,להפצה מקומית.`}<br />
                                                    {`מרגע קבלת הודעת קליטה מחברת בר הפצה, ניתן יהיה לעקוב אחרי החבילה מול בר הפצה.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '15'} onChange={handleChange('15')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '15' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`מי חברת ההפצה שלכם בישראל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`לאחר שהחבילה הגיעה לישראל ושוחררה מהמכס, היא מועברת למרכז הפצה של חברת בר הפצה`} <br/> { `ממנו תשלח במהירות ליעדה בנקודת האיסוף או בבית הלקוח` }<br />
                                                    {`מרגע זה העדכון יתבצע ע"י מסרונים מחברת ההפצה. תהליך ההפצה נמשך מספר ימים.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '16'} onChange={handleChange('16')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '16' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אפשר לאחסן חבילות במחסני רד-בוקס פרסל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `כן, אחסון חבילה במחסנים שלנו הינו ללא עלות ב-21 הימים הראשונים, ולאחר מכן בעלות של 4$ לשבוע (7 ימים) או לחלק מהשבוע. חבילה שתמצא במחסנים באירופה/בארה"ב למעלה מ-60 ימים מבלי ששולם עבורה משלוח לארץ, או חבילה שלא תאסף על ידי הלקוח מנקודת ההפצה בארץ ולא תדרש במשך 60 יום לאחר מכן תוגדר כ"חבילה נטושה" וחברת רדבוקס לא תהיה מחוייבת להחזיק בה יותר`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '17'} onChange={handleChange('17')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '17' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן להחזיר חבילה לשולח?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `אנו מבצעים החזרה מהמחסן בחו"ל למוכר בלבד (לא מהארץ לחו"ל). במידה והחבילה הגיעה למרכז הלוגיסטי,נקלטה ועדיין לא שולמה, ניתן לבקש label להחזרה מהמוכר, לשלוח אלינו למייל ואנו נדאג לטיפול בהחזרה מול חברות הובלה המקומיות. עלות ההחזרה (ההובלה המקומית) הינה באחריות המוכר. הטיפול בהחזרת החבילה לחברת ההובלה המקומית על ידי רדבוקס כרוך בתשלום של 10$.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '18'} onChange={handleChange('18')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '18' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אפשר לייבוא דרכם חבילות במשקלים ו/או אורכים חריגים?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `כן, בוודאי. המחירון שלנו כולל חבילות במשקלים של עד 100 ק"ג. אם תרצו להביא חבילה שמשקלה גבוה יותר, יש לפנות לשירות הלקוחות עם פרטי המשלוח לקבלת הצעת מחיר.  חבילה שמשקלה הפיזי או הנפחי מעל 20 ק"ג תסופק עד לכניסה לבניין ולא עד לדלת הדירה.`} <br/>
                                                    { `חבילות באורכים חריגים: לחבילה שאחת מצלעות החבילה ארוכה מ-110 ס"מ (ועד 140 ס"מ)  תתווסף עלות משלוח בסך 40$. עבור חבילות שמידת אחת מצלעותיהן עולה על 55 אינץ' (140 ס"מ) יש לפנות לשירות לקוחות לתמחור המשלוח. `}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '19'} onChange={handleChange('19')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '19' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם יש הגבלה על מספר המוצרים שניתן לייבא בייבוא אישי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `על פי צו ייבוא אישי, כמות סבירה היא עד 30 יחידות במשלוח שעלותו 1,000 דולרים , או עד 5 יחידות במשלוח שעלותו מעל 1,000 דולרים. יצוין כי חבילות שנשלחות למייבא מאותו ספק בהפרש של עד 72 שעות נחשבות כמשלוח אחד. אך יש גם חריגים : בכל מקרה של ספק ניתן להתייעץ עם עמילות המכס שלנו באמצעות פניייה לנציג שירות (למשל,  למשל אלכוהול ההגבלה עד 5 ליטר ייבוא אישי מיועד לצריכה אישית וקיימת הגבלה על מספר המוצרים שניתן לייבא באופן פרטי).`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px", textDecoration: "underline" }}>{ ` שאלות לגבי שירותים נוספים:`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '20'} onChange={handleChange('20')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '20' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אתם עושים איחוד חבילות?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `כן. ניתן להגיש בקשה לאיחוד חבילות עבור חבילות המופיעות באזור האישי שלך באתר ואשר לא שולמו עדיין. בשל מגבלות משקל וגודל , ישנם מקרים שבהם צוות המחסן לא יוכל לבצע איחוד חבילות. לא ניתן להוסיף לחבילה מאוחדת חבילה נוספת לאחר שכבר בוצע האיחוד,  וכמו כן לא ניתן לפצל חבילות בחזרה לאחר שהאיחוד בוצע פיזית על ידי המחסן. לצרכי מס יש להצהיר על השווי הכולל של כל החבילות הנכללות באיחוד. כלומר, גם אם כל חבילה כוללת מוצרים מתחת ל75$, ויחד סכום המוצרים גבוה מ 75$ החבילה המאוחדת תהיה חייבת במס.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '21'} onChange={handleChange('21')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '21' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם שירות איחוד חבילות כרוך בתשלום?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `השירות כרוך בתשלום כמפורט בדף המחירון באתר. עבור הזמנה שפוצלה על ידי הספק  האיחוד לא כרוך בתשלום.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '22'} onChange={handleChange('22')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '22' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`כיצד מתבצע איחוד החבילות?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`איחוד החבילות מתבצע על ידי לחיצת כפתור באזור האישי שלך באתר, רק כאשר שכל החבילות שברצונך לאחד יקלטו במחסן יופיעו באזור האישי שלך, ולפני התשלום עבור המשלוח : `}
                                                    <ul>
                                                        <li> { `1.	לוחצים על כפתור "איחוד חבילות" המופיע מעל רשימת החבילות שלך באזור האישי.`}</li>
                                                        <li> { `2.	מסמנים את החבילות אותן ברצונך לאחד, `}</li>
                                                        <li> { `3.	מאשרים את הבקשה באמצעות לחיצה על כפתור OK.`}</li>
                                                        <li> { `4.	בסיום התהליך תישלח הודעת תשלום חדשה עבור החבילה המאוחדת. `}</li>
                                                    </ul>
                                                    { `הערות חשובות:`} <br/>
                                                    { `*ניתן לאחד רק חבילות שטרם שילמת עבור המשלוח שלהן`} <br/>
                                                    { `*בשל מגבלות משקל וגודל , ישנם מקרים שבהם צוות המחסן לא יוכל לבצע איחוד חבילות. `} <br/>
                                                    { `*לא ניתן להוסיף לחבילה מאוחדת חבילה נוספת לאחר שכבר בוצע האיחוד,  וכמו כן לא ניתן לפצל חבילות בחזרה לאחר שהאיחוד בוצע על ידי המחסן.`} <br/>
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '23'} onChange={handleChange('23')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '23' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`תוך כמה זמן אראה את החבילה המאוחדת שלי באזור האישי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`המרכז הלוגיסטי שלנו מקבל את הבקשה, מאחד את החבילות פיזית ומעדכן באזור האישי את החבילה המאוחדת עם המידות החדשות ומס' החבילה החדש הכולל בתוכו את החבילות האחרות ,תהליך זה לרוב אורך עד כ3 ימי עסקים מרגע הבקשה באתר`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '25'} onChange={handleChange('25')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '25' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן לבטל איחוד חבילות?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`ניתן לבקש משירות הלקוחות לבטל בקשה לאיחוד חבילות,  רק אם טרם בוצע האיחוד פיזית והבקשה ממתינה לאיחוד. לא ניתן לבטל איחוד חבילות לאחר שהאיחוד בוצע על ידי המחסן.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '251'} onChange={handleChange('251')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '251' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `האם ניתן לצמצם את נפח החבילות?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `ניתן להגיש בקשה למחסן לצמצום את נפח החבילה בלחיצת כפתור באזור האישי שלך באתר (לוחצים על "שירותים נוספים"  ובוחרים ב"צמצום נפח"). מומלץ לבקש את השירות במידה וקיים קיים פער משמעותי בין גודל המוצר לנפח החבילה`}<br />
                                                    { `השירות כרוך בתשלום נוסף (עבור כל חבילה, פרט לחבילות המכילות מוצרי אופנה) כמפורט במחירון באתר. `}<br />
                                                    { `במידה וחבילות המכילות פריטי אופנה הגיעו בקרטון ולא בשקיות שילוח, נוכל להציא את הפריטים מהאריזה המקורית ולארוז אותם מחדש בשקיות השילוח שלנו. `}<br />
                                                    { `לא ניתן לצמצם נפח עבור מוצרים שבירים או רגישים.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '26'} onChange={handleChange('26')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '26' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `האם ניתן לקבל צילום של תכולת החבילה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `ניתן להגיש בקשה למחסן לצילום תכולת החבילה בלחיצת כפתור באזור האישי שלך באתר (לוחצים על "שירותים נוספים"  ובוחרים ב"צילום תכולה"). השירות כרוך בתשלום נוסף כמפורט במחירון באתר.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px" }}>{ `שאלות לגבי מוצרים שונים:`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '27'} onChange={handleChange('27')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '27' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן לייבא מוצרי מזון?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `מוצרי מזון ביבוא אישי - מותר לייבא עד 15 ק"ג למשלוח .`}<br />
                                                    { `אולם, כל סוג של מוצר לא יעלה על 5 ק"ג (למשל, עד 5 קילו ממתקים).`}<br />
                                                    { `אסור יבוא של מזונות רגישים לדוגמא: דברים חיים, גבינות , דגים , בשר אבקות חלב.`}<br />
                                                    {`אסור יבוא של מזונות רגישים לדוגמא: דברים חיים, גבינות , דגים , בשר אבקות חלב.`}<span><a href="https://redboxparcel.com/affidavit.pdf" target="_blank"> {`לחץ כאן `}</a></span><br />
                                                    <span><a href="https://redboxparcel.com/affidavit.pdf" target="_blank">{ 'https://redboxparcel.com/affidavit.pdf'}</a></span><br />
                                                    
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '28'} onChange={handleChange('28')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '28' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ייבוא טלפונים סלולריים או מחשבים אישיים כפוף לאישור משרד התקשורת?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`ביבוא אישי ניתן לייבא עד 5 יחידות של טלפונים סלולאריים ומחשבים ניידים ללא צורך בהצגת אישורים ע"פ זאת על פי `} <span><a href="https://www.nevo.co.il/law_html/law01/502_470.htm#Seif3" target="_blank"> {` תקנות הטלגרף`}</a> &nbsp;</span>{ `האלחוטי (פטור מרישוי) של משרד התקשורת.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '29'} onChange={handleChange('29')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '29' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ישנם מוצרים אשר אסורים בייבוא לישראל?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `הייבוא האישי לישראל איננו ניתן ללא הגבלה וישנה רשימה של מוצרים וחומרים אשר אסורים בייבוא אישי והם מפורטים בצו המכס (איסור יבוא), תשס"ה-2005. צו זה מפרט מספר רמות של איסור ייבוא טובין לישראל – לעתים בהתאם לטובין עצמם ולעתים לפי ייעודם האסור בייבוא. למשל (לא רשימה סגורה):`}<br />
                                                    <ul>
                                                        <li style={{ listStyle: "inside" }}>{ `מוצרים מגונים או פרוצים.`}</li>
                                                        <li style={{ listStyle: "inside" }}>{ `דברי פרסומת וכרטיסים להימורים או הגרלות, שאין היתר מראש לעריכתם.`}</li>
                                                        <li style={{ listStyle: "inside" }}>{ `מכונות משחק ומשחקי מזל.`}</li>
                                                        <li style={{ listStyle: "inside" }}>{ `טובין שיש בהם משום הטעיה (לפי סעיף 2 לחוק הגנת הצרכן).`}</li>
                                                        <li style={{ listStyle: "inside" }}>{ `זיופים וחיקויים של מסמכי העדפה, חשבון מכר או תעודת מקור.`}</li>
                                                        <li style={{ listStyle: "inside" }}>{ `חיקויים וזיופים של מסמכים, מטבעות, שטרי בנק ושטרות כסף.`}</li>
                                                        <li style={{ listStyle: "inside" }}>{ `שקים משומשים אשר שימשו לאריזת חומרים צמחיים.`}</li>
                                                        <li style={{ listStyle: "inside" }}>{ `סכינים כהגדרתם לפי סעיף 184 לחוק העונשין (למעט אולרים ולמעט סכינים שנועדו למלאכה, מקצוע, צורכי בית, עסק או מטרה כשרה).`}</li>
                                                        <li style={{ listStyle: "inside" }}>{ `טובין המשמשים לשיבוש מדי מהירות בלייזר.`}</li>
                                                        <li style={{ listStyle: "inside" }}>{ `כלי ירייה דמויי אקדח הזנקה, עט, כליי ירידה המופעלים בגז וכדומה.`}</li>
                                                    </ul>
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '30'} onChange={handleChange('30')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '30' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ישנם מוצרים אשר לא ניתנים להטסה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `רשימת החומרים האסורים להטסה:`}<br />
                                                    { `בטריות Li-on (ליתיום איאון) ,שאינן מותקנות במכשיר.`}<br />
                                                    {`מוצרים דליקים (שאינם עומדים בנהלי התעופה של מוצרים דליקים ומסוכנים).`}<br />
                                                    {`גזים דחוסים (מוקפאים, דליקים, בלתי דליקים ורעילים).`}<br />
                                                    {`חומרים מאכלים, כגון: חומצות, חומרי בסיס, סוללות מופעלות בתא רטוב ופריטים הכוללים כספית.`}<br />
                                                    {`חומרי נפץ, תחמושת (כולל כדורים ריקים), אקדחים, זיקוקין, פיקות כדורי אקדח.`}<br />
                                                    {`נוזלים וחומרים מוצקים דליקים, כגון בשמים, לקים, מצתים שיש להטות לפני שמציתים אותם, דלק למציתים, גפרורים, מציתים, צבעים וחומרים מדללים.`}<br />
                                                    {`חומרים רדיו-אקטיביים.`}<br />
                                                    {`תיקי מנהלים עם מנגנוני אזעקה.`}<br />
                                                    {`חומרים מחמצנים, כגון אבקות הלבנה ומי חמצן.`}<br />
                                                    {`רעלים וחומרים מדבקים, כגון קוטלי חרקים, קוטלי עשבים וחומרים המכילים וירוסים.`}<br />
                                                    {`בהתאם לנהלי התעופה, עבור שילוח מוצרים דליקים נדרשים אישורי הטסה מיוחדים ואריזות בעלות תקן מיוחד, וגם במידה ויתקמו התנאים, לא ודאי שחברות התעופה יאשרו הטסה. לפיכך, יש להמנע מיבוא אישי של פרטים אלה האסורים לשילוח בינלאומי והמוזכרים לעיל, כחומרים אשר אסורים לשילוח.`}<br />
                                                    
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '0301'} onChange={handleChange('0301')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '0301' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן לייבא תרופה ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`במידה ומדובר בתרופה שלא דרוש לה מרשם רופא וכל עוד היא עונה על הגדרות ייבוא אישי, יש לחתום על טופס הצהרה למשרד הבריאות (הצהרה על ייבוא תכשיר לצרכיו האישיים של אדם).`}<br />
                                                    {`במידה ודרוש מרשם רופא לצורך קניית התרופה, יש לצרף לטופס ההצהרה גם מרשם רופא.`}<br />
                                                    <a href="https://www.gov.il/BlobFolder/generalpage/personal-import-tofes/he/files_publications_units_pharmaceutical_division_a3071_29a1.docx" target="_blank">https://www.gov.il/BlobFolder/generalpage/personal-import-tofes/he/files_publications_units_pharmaceutical_division_a3071_29a1.docx</a>
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '0302'} onChange={handleChange('0302')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '0302' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `מהן הגדרות היבוא האישי לתרופה?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `על מנת לייבא תרופה יש לוודא שהיא עונה על כל הקריטריונים הבאים`} <br />
                                                    { `1.	התרופה אינה מכילה סם מסוכן או חומר פסיכוטרופי.`}<br />
                                                    { `2.	בידיי מרשם רופא לשימוש בתכשיר, אם התכשיר מחייב מרשם רופא.`}<br />
                                                    { `3.	התכשיר מיועד לצריכה אישית לתקופה שאינה עולה על 90 ימים. `}<br />
                                                    { `4.	התכשיר נרכש מבית מרקחת מורשה במדינת המקור.`}<br />
                                                    { `5.	התכשיר לא יועבר לצד ג', בין בתמורה ובין שלא בתמורה.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '31'} onChange={handleChange('31')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '31' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם ניתן לייבא אביזרים לנשק ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `יבוא אביזרים וחלקים לכלי נשק מסווג בפרט 9305 ודורש רישיון יבוא: רשיון ממשרד הכלכלה ( מנהל יבוא ) / רישיון ממשרד לביטחון פנים ( אגף לפיקוח ורישוי כלי יריה ).`}<br />
                                                    { `רישיון היבוא הוא עניין מסחרי ולא ניתן להוציאו באופן אישי ( צריך להתבצע ע"י ארגונים מורשים הפועלים תחת רישיון ורגולציה של האגף לרישוי ופיקוח כלי ירייה ).`}<br />
                                                    <b>{ `המשמעות המעשית היא שיבוא אביזרים וחלקים לכלי נשק, אינו אפשרי ביבוא אישי.`}</b> <br/>
                                                    <b>{ `נרתיקים לנשק מותרים ביבוא.`}</b>
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '311'} onChange={handleChange('311')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '311' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `האם ניתן לייבא חלקי חילוף לרכבים ואופנועים?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`ניתן לייבא את מרבית חלקי חילוף לרכבים ואופנועים ובכך להוזיל את עלויות החלפים הגבוהות ברכישה מקומית. מרבית מוצרי התעבורה ביבוא אישי אינם נדרשים כיום באישורי שחרור ממשרד התחבורה. אך ישנה רשימה נפרדת של חלקים, אשר בעבורם נדרש אישור משרד התחבורה.  הקישור הבא מפרט את המוצרים הנדרשים לאישור משרד התחבורה:  `} <span><a href="/link2.pdf" target="_blank">{` מוצרי תעבורה – דרישות תקן`}</a></span> { `(יש לשים לב למוצרים שלצידם מצויין "יבוא אישי" ) .`}<br />
                                                    {`אם אתם מעוניינים לייבא חלקי חילוף בטיחותיים, בקישור המצורף ניתן להוריד את `} <span><a href="/link1.pdf" target="_blank">{` טופס יבוא מוצרי תעבורה `}</a></span> {`אותו יש להגיש למשרד התחבורה לצורך קבלת האישור המתאים.`} <br />
                                                    { `שירות הלקוחות שלנו ישמח לעזור לכם בתהליך.`}
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px", textDecoration: "underline" }}>{ `שאלות לגבי חנויות:`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '32'} onChange={handleChange('32')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '32' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`האם אפשר להזמין גם מחנויות שאינן מופיעות כחנויות מומלצות באתר?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `כן, בוודאי. ניתן לעשות הזמנה מכל אתר באיחוד האירופי ששולח עד לדלת המחסן שלנו בהולנד, או מכל אתר בארצות הברית שעושה משלוחים עד לדלת המחסן בניו ג'רזי. לכן חשוב לבחור באפשרות של הובלה עד הבית ולא לנקודת הפצה`}<br/>
                                                    { `לתשומת לבכם:  ההזמנה ממדינה שאינה שייכת לארצות האיחוד האירופי /ארה"ב עלולה לגרור עלויות נוספות של מסים מקומיים.  חבילות מסויימות עשויות להעצר במכס ההולנדי או האמריקאי. במקרה כזה, יש לקחת בחשבון כי הטיפול בשיחרורן מהמכס הההולנדי או האמריקאי אינו חלק מהשירות שאנו מציעים.`}<br/>
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '33'} onChange={handleChange('33')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '33' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{`איך מזמינים מזארה הולנד ?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    {`נרשמים לשירות באתר Redbox parcel ומקבלים כתובת בהולנד.`}<br />
                                                    <ul>
                                                        <li style={{ listStyle: "decimal"}}>{ `נכנסים לאתר זארה דרך הדפדפן (לא דרך האפליקציה), במדינה בוחריםNederland .`}</li>
                                                        <li style={{ listStyle: "decimal" }}>{ `מוסיפים לעגלת הקניות את המוצרים אותם תרצו להזמין`}</li>
                                                        <li style={{ listStyle: "decimal" }}>{ `בסיום השופינג,יש ללחוץ על תשלום ולהתחבר כאורח.`}</li>
                                                        <li style={{ listStyle: "decimal" }}>{ `Adress2: הינו מספר הלקוח שלך אצלנו. יש לרשום אותו בצמוד לשם כדי שיופיע על החבילה - ממלאים בפרטי הכתובת את הכתובת באירופה שקיבלתם מאיתנו`}</li>
                                                        <li style={{ listStyle: "decimal" }}>{ `מוסיפים את המחוז Noord Holand.`}</li>
                                                        <li style={{ listStyle: "decimal" }}>{ `לטובת אימות, רושמים את הטלפון הישראלי שלכם עם קידומת בינלאומית +972.`}</li>
                                                    </ul>
                                                    {`והנה ההסבר גם בהיילייטס בדף האינסטגרם שלנו: `}<br />
                                                    <a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE4MDI0NTg2NTQzODgxMjkx?story_media_id=3260387128980327668_56318942893&igsh=MTc4MmM1YmI2Ng==">{ `https://www.instagram.com/s/aGlnaGxpZ2h0OjE4MDI0NTg2NTQzODgxMjkx?story_media_id=3260387128980327668_56318942893&igsh=MTc4MmM1YmI2Ng==`}</a><br />
                                                    
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <h2 style={{ direction: "rtl", fontWeight: "bold", fontSize: "28px", color: "black", marginTop: "50px", textDecoration: "underline" }}>{ `ביטוח:`}</h2>
                                    <Accordion className='accordion' expanded={expanded === '34'} onChange={handleChange('34')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '34' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `האם ניתן לבטח את המטענים אשר נשלחים דרכנו`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `ברד בוקס ניתן ומומלץ לבטח את המוצרים בעת התשלום עבור המשלוח. הביטוח מכסה נזק פיזי למוצר או אובדן בתהליך השילוח, השחרור מהמכס וההפצה בארץ. ללא הביטוח לא ינתן פיצוי במקרה של אבדן או נזק לחבילה.`}<br/>
                                                    { `בסכומים של עד 250 $ ביטוח החבילות אינו חובה וניתן להסירו. בסכומים הגבוהים מ 250$ חלה חובת ביטוח.  `}<br/>
                                                    { `הפיצוי כולל את הערך של המוצרים בחשבונית, עלויות השילוח ומיסי הייבוא. שירות הביטוח נכנס לתוקף ברגע שבו שולם הביטוח עבור החבילה ומסתיים עם מסירתה ללקוח ו/או עם חתימתו על תעודת המשלוח.`}<br/>
                                                    
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '35'} onChange={handleChange('35')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '35' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `איך מעדכנים אתכם על נזק על מנת להפעיל את הביטוח?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    { `בכל מקרה של נזק ובקשה לכיסוי הביטוחי יש לפנות לכל המאוחר לשירות הלקוחות במהלך יום העסקים שלאחר קבלת החבילה.`}<br />
                                                    { `יש לתאר את הנזק ולהוסיף תמונות אשר ממחישות את תיאור הנזק.`}<br />
                                                    { `במקרה של הפעלת הביטוח לכיסוי הנדרש יחולו דמי השתתפות עצמית לפי המדרגות הבאות:`}<br />
                                                    { `עד 99 דולר – אין השתתפות עצמית`}<br />
                                                    { `100 דולר ועד 149 דולר - השתתפות עצמית בסך 25 דולר`}<br />
                                                    { `150 דולר ועד 449 דולר – השתתפות עצמית בסך של 40 דולר`}<br />
                                                    { `450 דולר ועד 1199 דולר – השתתפות עצמית בסך של 60 דולר`}<br />
                                                    { `מעל 1200 דולר – השתתפות עצמית בסך של 240 דולר`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className='accordion' expanded={expanded === '36'} onChange={handleChange('36')}>
                                        <AccordionSummary
                                            className='accordion-summary'
                                            expandIcon={expanded === '36' ? <RemoveIcon className='expandIcon' /> : <AddIcon className='expandIcon' />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography className='dir-rtl'>{ `באילו מקרים אין כיסוי ביטוחי?`}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className='accordion-details'>
                                            <Box className='dir-rtl accordion-content'>
                                                <Typography className='dir-rtl'>
                                                    <ul>
                                                        <li style={{ listStyle: "disc" }}>{ `במקרה של אובדן/נזק של חבילה שאינה מבוטחת, לא יקבל הלקוח זיכוי כלל`}</li>
                                                        <li style={{ listStyle: "disc" }}>{ `במקרים בהם רק האריזה נפגמה, אך המוצר ללא פגע, אין כיסוי ביטוחי.`}</li>
                                                        <li style={{ listStyle: "disc" }}>{ `במקרה של פגם חלקי במוצר, לא יהיה ניתן לבצע זיכוי חלקי אלא, יזוכה זיכוי מלא לאחר שהמוצר ייאסף על ידנו.`}</li>
                                                        <li style={{ listStyle: "disc" }}>{ `במקרים בה החבילה מגיעה מהספק פגומה או פתוחה, לא יבוצע זיכוי כלל.`}</li>
                                                        <li style={{ listStyle: "disc" }}>{ `במקרה בו החבילה נעצרה על ידי המכס או חברת התעופה מכיוון שכללה מוצרים האסורים בהטסה לארץ או ביבוא.`}</li>
                                                        <li style={{ listStyle: "disc" }}>{`חברת רד בוקס לא תהיה אחראית למשלוחים אשר הונחו מחוץ למחסן במהלך שבת או ראשון, או שנמסרו`}<br />{`לכתובת שגויה.`}</li>
                                                        <li style={{ listStyle: "disc" }}>{ `*אחריות הביטוח מוגבלת לסכום של עד 1,500 $.`}</li>
                                                        <li style={{ listStyle: "disc" }}>{ `הביטוח אינו מכסה את המוצרים הבאים:`}</li>
                                                        <li>
                                                            <ul>
                                                                <li style={{ listStyle: "circle" }}>{ `חפצים שבירים או רגישים– כלי פורצלן, זכוכית וקרמיקה, נרות  (כיסוי לאבדן בלבד)`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `יצירות וחפצי אומנות.`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `תכשיטים וחפצים יקרי ערך.`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `מוצרי יד שניה. (אובדן בלבד).`}</li>
                                                                <li style={{ listStyle: "circle" }}>{ `חבילות שעברו איחוד חבילות, צמצום נפח או אריזה מחדש (כיסוי לאבדן בלבד).`}</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    {`היה והתגלה כי הלקוח ביטח אחד מהמוצרים אותם הביטוח אינו מכסה, יהיה הלקוח זכאי להחזר דמי הביטוח `}<br />
                                                    {`אותם שילם.`}<br />
                                                </Typography>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>

                <Box component={'section'} className="faq-section dir-rtl" style={{ padding: '30px 0', fontSize:"20px" }}>
                    <Box className="container">
                        <p>{`לתנאי שימוש לחץ `}<span><a href="/TnC.pdf">{`כאן`}</a></span></p>
                    </Box>
                </Box>


                <Box component={'section'} className="faq-section bottom-section" sx={{ padding: { sm: '100px 0 0', xs: '0'} }}>
                    <Box className="container">
                        <Grid container className='dir-rtl box-wrapper'>
                            <Grid item sm={9} className='darkblue-rounded-box shadow_box'>
                                <Typography>לא מצאתם את מה שחיפשתם? נציגנו ישמחו לעזור לכם!</Typography>
                                <ActionButton textLabel={`הצג עוד`} className='underline-btn' onClick={() => {
                                    navigate("/contactUs")
                                }} />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default FAQS;