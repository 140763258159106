/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Fragment, useCallback, useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button, Table, TableBody, TableContainer, Paper, TablePagination, Box, Grid } from '@mui/material';
import './HeHeader.scss';
import { REQUEST_ACTIONS, sendRequest, setCookie, getCookie } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { getSession, setLanguages, onLanguageChange, setPackages, selectPackage } from '../../reducers/requestHandler';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { URL, LANGUAGES } from '../../constants/global-constants';
import { Dialog, Snackbar } from '../../style-guide';
import logo from '../../images/homepage/logo.png'
import ToggleIcon from '../../images/icons/menu.png'
import account from '../../images/homepage/account.png'
import account_white from '../../images/homepage/account_white.png'
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { FaUser } from "react-icons/fa";
import RedboxLogo from './../../images/newHomePage/redbox-logo.png';

export const HeHeader = ({ setIsRegister, setIsLogin, logOutUser, setActiveScreenInreg, setIsAddAddress, setIsProfile }) => {
    const { userInfo, innerContent } = useSelector(state => state.user);
    const { snackbar } = useSelector(state => state.snackbar);
    const navigate = useNavigate();
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));


    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [session, setSession] = new useState(getCookie("SESSION_ID"));
    const [isEnable, setEnabled] = new useState(true);
    const [isPackageLoaded, setPackageLoaded] = new useState(false);
    const [hasError, setHasError] = new useState(false);
    const [isToggle, setIsToggle] = new useState(false);
    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [isLoaded, setIsLoaded] = useState(true);

    const goTo = (e) => {
    }
    const keyPressed = (e) => {
        if (e.keyCode === 13) {
            //
        }
    }
    useEffect(() => {
    }, [userInfo]);

    const onclickLogo = () => {
        if (userInfo.session) {
            navigate("/accounts")
        } else {
            setIsLogin(true);
        }
    };

    const onClickLogout = () => {
        logOutUser();
        navigate("/home")
    };

    const navigate1 = (data) => {
        window.location = data + "?vers=" + (new Date()).getTime();
    }

    const firstNameLength = userInfo.user.firstName.length;

    return (
        <Box component={'header'} className="header_container">
            <Grid container className='container item_trl'>
                <Grid container item md={10} sm={10} className='header_logo_menu_items_container'>
                    <Grid container sx={{ display: { sm: 'block', xs: 'block', md: 'none' }, alignItems: 'center', justifyContent: 'center' }} onClick={() => setIsToggle(!isToggle)}>
                        <MenuIcon fontSize='large' className='menu_icon' />
                    </Grid>
                    <Grid container className="header_logo_image_container cursor_pointer" onClick={() => { navigate1("/home")}}>
                        <img src={RedboxLogo} className="header_logo_image" alt='' />
                    </Grid>
                    <Grid container sx={{ display: { sm: isToggle ? 'flex' : 'none', xs: isToggle ? 'flex' : 'none', md: 'flex' } }} className={`header_menu_items ${isToggle ? 'showOnMobile': 'hide'}`} onClick={() => setIsToggle(false)}>
                        
                        <Box component={'span'} className="header_menu_item" onClick={() => { navigate1("/FAQS") }}>איך זה עובד?</Box>
                        <Box component={'span'} className="header_menu_item" onClick={() => { navigate1("/redboxPricing") }}>מחירון</Box>
                        { < Box component={'span'} className="header_menu_item" onClick={() => { if (firstNameLength > 0) { setActiveScreenInreg('PersonalData'); } setIsRegister(true) }}>הכתובות שלי</Box>}
                        <Box component={'span'} className="header_menu_item" onClick={() => { navigate1("/comparePrices") }}>השוואת מחירים</Box>
                        <Box component={'span'} className="header_menu_item" onClick={() => { navigate1("/store") }}>חנויות מומלצות</Box>
                        {<Box component={'span'} className="header_menu_item" onClick={() => {
                            window.location = "/blog"
                            // navigate("/blog")
                        }}>בלוג</Box>}
                        <Box component={'span'} className="header_menu_item" onClick={() => { navigate1("/aboutUs") }}>אודות </Box>
                        {/*<Box component={'span'} className="header_menu_item" onClick={() => { navigate("/FAQS") }}>שאלות ותשובות</Box>*/}
                        <Box component={'span'} className="header_menu_item" onClick={() => { navigate1("/contactUs") }}>שירות לקוחות</Box>
                        

                       

                        

                        {firstNameLength > 0 && <Box component={'span'} className="header_menu_item" onClick={() => {
                            //navigate("/accounts")
                            navigate1("/accounts");
                        }}>אזור אישי</Box>}
                        {/*{firstNameLength > 0 && <Box component={'span'} className="header_menu_item" onClick={() => { setIsProfile(true) }}>הגדרות</Box>}*/}
                        {/*<Box component={'span'} className="header_menu_item" onClick={onclickLogo}>אזור אישי</Box>*/}
                        {/*{firstNameLength > 0 &&<Box component={'span'} className="header_menu_item" onClick={() => { setIsAddAddress(true) }}>הצג כתובות </Box>}*/}
                        {firstNameLength === 0 && <Box component={'span'} sx={{ display: { xs: 'flex', sm: 'none !important' }}} className="header_menu_item" onClick={() => setIsRegister(true)}>לקבלת כתובת</Box>}
                        
                        
                        {firstNameLength > 0 && <Box component={'span'} sx={{ display: { xs: 'flex', sm: 'none !important' } }} className="header_menu_item" onClick={() => onClickLogout()}> התנתק </Box>}

                        
                    </Grid>
                </Grid>
                {firstNameLength === 0 &&
                    <Grid container item md={2} sm={2} className="header_button_items" id="login_reg_div">
                        
                    <Box component={'span'} sx={{ display: { xs: 'none', sm: "inline"}}} className="header_button_filled darkgray_text" onClick={() => setIsRegister(true)}>לקבלת כתובת</Box>
                        <Box component={'span'} className="header_button_normal darkgray_text" onClick={() => setIsLogin(true)}>
                        <img src={account_white} className="header_account_image" alt='' /><Box component={'span'} sx={{ display: { xs: 'none', sm: "inline"}}}>{'התחברות'}</Box>
                            
                        </Box>
                        
                    </Grid>}
                {firstNameLength > 0 &&
                    <Grid container item md={2} sm={2} className="header_button_items" id="welcome_text_div">
                        <p className="welcome_text darkgray_text">

                            <Box component={'span'} id="first_name_span" onClick={() => {
                                //window.location = "/accounts"
                                navigate1("/accounts")
                            }} style={{ textAlign: "center" }}>
                                {userInfo.user.firstName} <Box component={'span'} sx={{ display: { xs: 'none', sm:"inline"}}}><br/> {userInfo.user.lastName}</Box>
                            </Box>
                            <FaUser title={ 'Logout'} style={{marginRight:"7px"}} onClick={onClickLogout}/>
                            {/*<img src={account_white} className="header_account_image" alt='' />*/}
                            <Box component={'span'} className='welcome_text logout anchor darkgray_text' sx={{ display: { xs: 'none' } }} style={{ marginRight: '10px' }} onClick={() => { onClickLogout() }}>התנתק 
                            </Box>
                            {/*<Box component={'span'} className='welcome_text logout anchor darkgray_text' sx={{ display: { xs: 'none' } }} style={{ marginRight: '10px' }} onClick={() => { window.location = 'http://localhost:3001/home' }}>He
                            </Box>
                            <Box component={'span'} className='welcome_text logout anchor darkgray_text' sx={{ display: { xs: 'none' } }} style={{ marginRight: '10px' }} onClick={() => { window.location = 'http://localhost:3001/Ar/home' }}>Ar
                            </Box>*/}
                        </p>
                    </Grid>}
            </Grid>
        </Box>
    );
}

export default HeHeader;